<div *ngIf="data.id; else templateName">
  <h1 *ngIf="'actionExec' in historic;" mat-dialog-title> {{ historic.actionExec }} of table {{ historic.tableExec
    }} </h1>
</div>

<ng-template #templateName>
  <h1 *ngIf="'actionExec' in historic;" mat-dialog-title> {{ historic.actionExec }} of table {{ historic.tableExec
    }} </h1>
</ng-template>


<div mat-dialog-content>
  <div *ngIf="historic.actionExec !== 'Delete'; else templatecheck">
    <table mat-table [dataSource]="dataSourceDetails" matSort>
      <!-- ID Column -->
      <ng-container matColumnDef="champ">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Field</th>
        <td mat-cell *matCellDef="let row"> {{row.fieldName}}</td>
      </ng-container>
      <!-- ID Column -->
      <ng-container matColumnDef="valuebefore">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Value before</th>
        <td mat-cell *matCellDef="let row"> {{row.valueBefore}}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="valueafter">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Value after</th>
        <td mat-cell *matCellDef="let row"> {{row.valueAfter}}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> createdAt</th>
        <td mat-cell *matCellDef="let row"> {{row.createdAt}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsDetails"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsDetails;"></tr>
    </table>

    <!-- <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 50, 25, 100]"></mat-paginator> -->
  </div>
  <ng-template #templatecheck>
    <mat-card>
      <!--{{ .email }}-->
      <table style="text-align: center" border="2px">
        <thead>

        <th *ngFor="let produit of historic.values; let index = index"><b
          *ngIf="historic.keys[index] !== '_id' && historic.keys[index] !== 'id' && historic.keys[index] !== '__v' && historic.keys[index] !== 'passWord' && historic.keys[index] !== 'isDelete' && historic.keys[index] !== 'isDeleted' && historic.keys[index] !== 'Password'">{{
          historic.keys[index] }}</b></th>

        </thead>
        <tbody>
        <tr>
          <td *ngFor="let produit of historic.values; let index = index"><b
            *ngIf="historic.keys[index] !== '_id' && historic.keys[index] !== 'id' && historic.keys[index] !== '__v' && historic.keys[index] !== 'passWord' && historic.keys[index] !== 'isDelete' && historic.keys[index] !== 'isDeleted' && historic.keys[index] !== 'Password'">{{
            historic.values[index] }}</b></td>


        </tr>
        </tbody>
      </table>
    </mat-card>
  </ng-template>
</div>
<div mat-dialog-actions>
  <button mat-button color="warn" (click)="onNoClick()">Exit</button>
</div>
