import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {DialogOverviewComponent} from './dialog-overview/dialog-overview.component';
// import { Routes } from '@angular/router';
import {MagasinService} from './magasin.service';
import {Magasin} from './magasin.model';
import {DialogAskComponent} from './dialog-ask/dialog-ask.component';
import {DialogManagerComponent} from './dialog-manager/dialog-manager.component';
import Swal from 'sweetalert2';
import {Router} from '@angular/router';


@Component({
  selector: 'app-magasin',
  templateUrl: './magasin.component.html',
  styleUrls: ['./magasin.component.scss']
})
export class MagasinComponent implements OnInit {
  displayedColumns = ['code', 'Name', 'Location', 'Manager', 'actions'];
  dataSource: MatTableDataSource<Magasin>;
  users: Magasin[] = [];
  Name: string;
  Location: string;
  Manager: string;
  managers: any;
  user = JSON.parse(sessionStorage.getItem('user'));
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private magasinService: MagasinService, public dialog: MatDialog, private router: Router) {

    this.fetchproduit();
    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource(this.users);
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogOverviewComponent, {
      width: '450px',
      data: {
        name: '',
        locations: '',
        code: ''
      }
    });

    const Toast = this.Toast;
    dialogRef.afterClosed().subscribe(result => {

      this.magasinService.addMagasin(result.name, result.locations, result.code)
        .subscribe((data: Magasin[]) => {
          const responce = JSON.parse(JSON.stringify(data));
          console.log(responce);
          if (responce.code === 200) {
            Toast.fire({
              icon: 'success',
              title: responce.message
            });

            this.fetchproduit();
          } else {
            Toast.fire({
              icon: 'error',
              title: responce.message,
            });
          }
        });

    });
  }

  openDialogWithId(idDialog: string): void {
    let company;
    this.users.forEach((value) => {
      if (idDialog === value.warehouse._id) {
        company = value;
      }
    });
    const Toast = this.Toast;

    const dialogRef = this.dialog.open(DialogOverviewComponent, {
      width: '450px',
      data: {
        id: idDialog,
        code: company.warehouse.code,
        name: company.warehouse.name,
        locations: company.warehouse.location,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // test et moddification a la bdd
      // result est l'objet qui comporte tous les champs
      this.magasinService.updateWarehouse(idDialog, result.name, result.locations, result.code)
        .subscribe((data: Magasin[]) => {
          this.fetchproduit();
        });

    });
  }

  fetchproduit() {

    this.magasinService
      .getCompanys()
      .subscribe((data) => {
        this.managers = data;
      });

    Swal.fire({
      title: 'Chargement',
      html: 'It will closed',
      onOpen: () => {
        Swal.showLoading();
        this.magasinService
          .getWarehouses()
          .subscribe((data: Magasin[]) => {
            this.users = data;
            this.dataSource = new MatTableDataSource(this.users);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            Swal.close();
          });
      },
      onClose: () => {
        // clearInterval(timerInterval)
      }
    }).then((result) => {
      if (
        // Read more about handling dismissals
        result.dismiss === Swal.DismissReason.timer
      ) {
        console.log('I was closed by the timer');
      }
    });
  }

  delete(id: string): void {
    const dialogRef = this.dialog.open(DialogAskComponent, {
      width: '350px',
      data: {title: 'delete'}
    });

    const Toast = this.Toast;

    dialogRef.afterClosed().subscribe(result => {
      // test et moddification a la bdd
      // result est l'objet qui comporte tous les champs
      if (result) {
        this.magasinService
          .deleteWarehouse(id)
          .subscribe((data: Magasin[]) => {
            const responce = JSON.parse(JSON.stringify(data));
            if (responce.code === 200) {
              Toast.fire({
                icon: 'success',
                title: responce.message
              });

              this.fetchproduit();
            } else {
              Toast.fire({
                icon: 'error',
                title: responce.message
              });
            }
          });
      }
    });
  }

  deletewarehouse(id: string): void {
    const dialogRef = this.dialog.open(DialogAskComponent, {
      width: '350px',
      data: {title: 'delete'}
    });

    const Toast = this.Toast;

    dialogRef.afterClosed().subscribe(result => {
      // test et moddification a la bdd
      // result est l'objet qui comporte tous les champs
      if (result) {
        this.magasinService
          .deleteWarehousemanager(id)
          .subscribe((data: Magasin[]) => {
            const responce = JSON.parse(JSON.stringify(data));
            if (responce.code === 200) {
              Toast.fire({
                icon: 'success',
                title: responce.message
              });

              this.fetchproduit();
            } else {
              Toast.fire({
                icon: 'error',
                title: responce.message
              });
            }
          });
      }
    });
  }

  addManager(idWarehouse): void {

    const dialogRef = this.dialog.open(DialogManagerComponent, {
      width: '350px',
      data: {
        id: idWarehouse,
        manager: '',
        managers: this.managers
      }
    });

    const Toast = this.Toast;

    dialogRef.afterClosed().subscribe(result => {
      // test et moddification a la bdd
      // result est l'objet qui comporte tous les champs
      if (result) {

        this.magasinService
          .addManager(idWarehouse, result.manager)
          .subscribe((data: Magasin[]) => {
            const responce = JSON.parse(JSON.stringify(data));
            if (responce.code === 200) {
              Toast.fire({
                icon: 'success',
                title: responce.message
              });

              this.fetchproduit();
            } else {
              Toast.fire({
                icon: 'error',
                title: responce.message
              });
            }
          });
      }
    });
  }

  redirect(id): void {
    this.router.navigate(['admin/warehouse/' + id]);
  }

  changeWarehouse(id: string, status: string): void {
    const dialogRef = this.dialog.open(DialogAskComponent, {
      width: '350px',
      data: {title: 'change status'}
    });

    const Toast = this.Toast;
    dialogRef.afterClosed().subscribe(result => {
      // test et moddification a la bdd
      // result est l'objet qui comporte tous les champs
      if (result) {
        let newStatus;
        if (status === 'active') {
          newStatus = 'Deactive';
        } else {
          newStatus = 'active';
        }
        this.magasinService
          .changeWarehousestatus(id, newStatus)
          .subscribe((data: Magasin[]) => {
            const responce = JSON.parse(JSON.stringify(data));
            if (responce.code === 200) {
              Toast.fire({
                icon: 'success',
                title: responce.message
              });

              this.fetchproduit();
            } else {
              Toast.fire({
                icon: 'error',
                title: responce.message,
              });
            }
          });
      }
    });
  }
}
