<h1>Manage Rapport</h1>
<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h2>Generate Rapport</h2>
      <div class="addcomp">
        <button style='background: #000;margin-left: 5px;' mat-raised-button (click)="openDialog()" color="warn"><mat-icon class="sidenav-icon">add_box</mat-icon> Generate</button>
      </div>
    </mat-card-title>
  </mat-card-header>
  <!--<mat-card-content>-->
    <!--<mat-form-field>-->
        <!--<input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">-->
    <!--</mat-form-field>-->

    <!--<div class="mat-elevation-z8">-->
        <!--<table mat-table [dataSource]="dataSource" matSort>-->

            <!--&lt;!&ndash; ID Column &ndash;&gt;-->
            <!--<ng-container matColumnDef="date">-->
                <!--<th style='width: 7%;' mat-header-cell *matHeaderCellDef mat-sort-header> end date </th>-->
                <!--<td mat-cell *matCellDef="let row"> {{row.date}} </td>-->
            <!--</ng-container>-->
            <!--&lt;!&ndash; ID Column &ndash;&gt;-->
            <!--<ng-container matColumnDef="file">-->
                <!--<th style='width: 7%;' mat-header-cell *matHeaderCellDef mat-sort-header> Order Number </th>-->
                <!--<td mat-cell *matCellDef="let row"> {{row.file}} </td>-->
            <!--</ng-container>-->

            <!--&lt;!&ndash; Color Column &ndash;&gt;-->
            <!--<ng-container matColumnDef="actions">-->
                <!--<th style='width: 25%;' mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>-->
                <!--<td mat-cell id='actions' *matCellDef="let row">-->
                    <!--<button mat-raised-button class="btn-xs" color="warn" (click)='refresh(row.date)' style='background: rgb(254, 192, 13);'><mat-icon class="sidenav-icon">refresh</mat-icon><b>Refresh</b></button>-->


                    <!--<button mat-raised-button class="btn-xs" color="warn" (click)='exports(row.file)' style='background: rgb(49, 176, 213);'><mat-icon class="sidenav-icon">get_app</mat-icon><b>Download</b></button>-->
                 <!--</td>-->
            <!--</ng-container>-->


            <!--<tr mat-header-row *matHeaderRowDef="displayedColumn"></tr>-->
            <!--<tr mat-row *matRowDef="let row; columns: displayedColumn;"></tr>-->
        <!--</table>-->
        <!--<mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 50, 25, 100]" showFirstLastButtons></mat-paginator>-->
    <!--</div>-->
  <!--</mat-card-content>-->
</mat-card>
