<div id="sidebar">
  <mat-nav-list>
    <div *ngIf="user.role != 'StockController'">
      <a mat-list-item [routerLinkActive]="'active'" [routerLink]="['/admin/company']">
        <mat-icon class="sidenav-icon">business</mat-icon>
        {{ 'Manage Company' | translate }}
      </a>
      <a mat-list-item [routerLinkActive]="'active'" [routerLink]="['/admin/manage-order']">
        <mat-icon class="sidenav-icon">menu</mat-icon>
        {{ 'Manage Orders' | translate }}
      </a>
      <a mat-list-item [routerLinkActive]="'active'" [routerLink]="['/admin/manage-products']">
        <mat-icon class="sidenav-icon">add_box</mat-icon>
        {{ 'Manage Produits' | translate }}
      </a>
    </div>
    <div>
      <a mat-list-item [routerLinkActive]="'active'" [routerLink]="['/admin/warehouse']">
        <mat-icon class="sidenav-icon">house</mat-icon>
        {{ 'Manage Stock' | translate }}
      </a>
    </div>
    <div *ngIf="user.role != 'StockController'">
      <a mat-list-item [routerLinkActive]="'active'" [routerLink]="['/admin/warehousemanager']">
        <mat-icon class="sidenav-icon">group</mat-icon>
        {{ 'WarehouseManager' | translate }}
      </a>
    </div>
    <div *ngIf="user.role == 'admin'">
      <a mat-list-item [routerLinkActive]="'active'" [routerLink]="['/admin/audit']">
        <mat-icon class="sidenav-icon">work</mat-icon>
        {{ 'Audit' | translate }}
      </a>
    </div>
    <a mat-list-item [routerLinkActive]="'active'" [routerLink]="['/admin/rapport']">
      <mat-icon class="sidenav-icon">description</mat-icon>
      {{ 'Rapport' | translate }}
    </a>

  </mat-nav-list>
</div>
