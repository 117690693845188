import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

// import { RequestOptions,Headers } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class RapportService {
  uri = localStorage.getItem('serverRoot');
  user = JSON.parse(sessionStorage.getItem('user'));

  constructor(private http: HttpClient) {
  }

  addRapport(dateField, finField) {
    const formData = {
      date: dateField,
      fin: finField
    };
    return this.http.post(`${this.uri}/admins/${this.user._id}/generateRapport`, formData);
  }

  addSummary(dateField, finField) {
    const formData = {
      date: dateField,
      fin: finField
    };
    return this.http.post(`${this.uri}/admins/${this.user._id}/summarys`, formData);
  }

  stock(dateField, finField) {
    const formData = {
      date: dateField,
      fin: finField
    };
    return this.http.post(`${this.uri}/admins/${this.user._id}/generateRapportStock`, formData);
  }

  addRapportByWarehouse(dateField, finField, warehouseField) {
    const formData = {
      date: dateField,
      fin: finField,
      warehouse: warehouseField
    };
    return this.http.post(`${this.uri}/admins/${this.user._id}/generateRapportWarehouse`, formData);
  }

  getRapports() {
    return this.http.get(`${this.uri}/admins/${this.user._id}/file`);
  }

  getWarehouses() {
    return this.http.get(`${this.uri}/admins/${this.user._id}/warehouses`);
  }

}
