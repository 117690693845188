import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {ViewdetailserviceService} from './viewdetailservice.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-viewdetails',
  templateUrl: './viewdetails.component.html',
  styleUrls: ['./viewdetails.component.scss']
})
export class ViewdetailsComponent implements OnInit {
  dataSourceDetails: MatTableDataSource<Details>;
  details: Details[];
  historic: any;
  displayedColumnsDetails = ['champ', 'valuebefore', 'valueafter', 'createdAt'];

  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });

  ngOnInit() {
    this.sync(this.data).then((v) => {
      this.dataSourceDetails = new MatTableDataSource(this.details);
    });
  }

  constructor(private viewdetailserviceService: ViewdetailserviceService,
              public dialogRef: MatDialogRef<ViewdetailsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  async sync(data) {
    const Toast = this.Toast;

    await Swal.fire({
      title: 'Chargement',
      html: 'It will closed',
      onOpen: () => {
        Swal.showLoading();

        this.viewdetailserviceService
          .viewdetail(data.id)
          .subscribe((datas) => {
            if (datas['code'] === 200) {
              // console.log(Object.keys(datas['message'].historic.idTable));
              datas['message'].historic.keys = Object.keys(datas['message'].historic.idTable);
              datas['message'].historic.values = Object.values(datas['message'].historic.idTable);
              this.historic = datas['message'].historic;
              this.details = datas['message'].historicFIeld as Details[];
            } else {
              Toast.fire({
                icon: 'error',
                title: 'error'
              });
            }
            Swal.close();
          });
      },
      onClose: () => {
        // clearInterval(timerInterval)
      }
    }).then((result) => {
      if (
        // Read more about handling dismissals
        result.dismiss === Swal.DismissReason.timer
      ) {
        console.log('It was closed by the timer');
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

export interface Details {
  fieldName: string;
  valueBefore: string;
  valueAfter: string;
  createdAt: string;
}
