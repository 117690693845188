<div *ngIf="data.id; else templateName">
    <h1 mat-dialog-title>Edit User</h1>
</div>

<ng-template #templateName>
    <h1 mat-dialog-title>Create bach</h1>
</ng-template>

<div mat-dialog-content>
  <div fxFlex fxLayout="row" style="text-align=center;display: block;" fxLayout.lt-md="column" fxLayoutGap="3px">
    <div fxFlex>
    </div>
      <div fxFlex>
        <mat-form-field>
            <mat-select style="z-index:1000;" [(value)]="data.Companies" [(ngModel)]="data.Companies" placeholder="Companies">
              <mat-option *ngFor="let company of data.companys" [value]="company">
                {{company}}
              </mat-option>
            </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex>
          <mat-checkbox class="example-margin" [(ngModel)]="checked">Single Order</mat-checkbox>
      </div>
      <div fxFlex>
      </div>
  </div><br/>
  <mat-card>
    <div class="text-center" fxFlex fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px">
      <div fxFlex class="parentDiv"><div class="roundClass">1</div> Upload </div>
      <div fxFlex class="parentDiv"><div class="roundClass">2</div> Option </div>
      <div fxFlex class="parentDiv"><div class="roundClass">3</div> Date </div>
      <div fxFlex class="parentDiv"><div class="roundClass">4</div> Purchase Order </div>
    </div>
  </mat-card><br/>
  <mat-card>
    <div *ngIf="checked; else templatecheck">
      <mat-form-field >
          <input matInput  [(ngModel)]="data.firstname" placeholder="First Name">
      </mat-form-field>
      <mat-form-field>
          <input matInput  [(ngModel)]="data.lastname" placeholder="Last Name">
      </mat-form-field>
      <mat-form-field>
          <input matInput  [(ngModel)]="data.employeid" placeholder="Employe Id">
      </mat-form-field>
      <mat-form-field>
          <input matInput  [(ngModel)]="data.location" placeholder="pickup Location">
      </mat-form-field>
      <mat-form-field>
          <input matInput  [(ngModel)]="data.departement" placeholder="Departement">
      </mat-form-field>
      <div *ngFor="let produit of data.produits" class="produitsdiv">
        <mat-form-field  class="example-margin">
              <input matInput type="number" [(ngModel)]="data[produit]" placeholder="{{produit}}">
        </mat-form-field>
      </div>
    </div>

    <ng-template #templatecheck>
      <div class="sendfille">
        <b>Download sample file, put employee details & allowances, upload file</b><br/><br/>
        <a (click)='simple()' style='cursor: pointer;color: #23527c;'>Download sample file here</a>
      </div><br/><br/><br/>
      <div class="sendfille">
        <i *ngIf="name"> {{name}} </i>
        <i *ngIf="data"> {{data.document}}   </i>
        <button type="button" mat-raised-button md-button (click)="imgFileInput.click()">Browse</button>
        <input hidden type="file" (change)="onSelectFile($event)" #imgFileInput [(ngModel)]="data.document"/>
      </div>
    </ng-template>
  </mat-card><br/>
    <mat-card id='pickers'>
      <div fxFlex fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px">
          <div fxFlex>
              <mat-form-field>
                <mat-select [(ngModel)]="data.fulfillmentoption" placeholder="Choose fulfillment option">
                    <mat-option value="Distribution by Gocongo">Distribution by Gocongo</mat-option>
                    <mat-option value="Self Distribution">Self Distribution</mat-option>
                </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex>
              <mat-form-field>
                <input matInput [(ngModel)]="data.date" [matDatepicker]="picker" placeholder="Choose a start date">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div fxFlex>
              <mat-form-field>
                <input matInput [(ngModel)]="data.datefin" [matDatepicker]="picke" placeholder="Choose an end date">
                <mat-datepicker-toggle matSuffix [for]="picke"></mat-datepicker-toggle>
                <mat-datepicker #picke></mat-datepicker>
            </mat-form-field>
          </div>
      </div>
    </mat-card>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">No Thanks</button>
    <button id="add" mat-button [mat-dialog-close]="data" cdkFocusInitial>Submit request and seek approval</button>
</div>
