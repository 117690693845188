<h1>Manage Product</h1>
<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h2>Product List</h2><button *ngIf="user.role == 'admin'" style='background: #000;' mat-raised-button (click)="openDialog()" class="addcomp" color="warn"><mat-icon class="sidenav-icon">add_box</mat-icon> Add Product</button>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
    </mat-form-field>

    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>

            <!-- ID Column -->
            <ng-container matColumnDef="productno">
                <th style='width: 8%;' mat-header-cell *matHeaderCellDef mat-sort-header> product No. </th>
                <td mat-cell *matCellDef="let row"> {{row.productNo}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="productname">
                <th style='width: 9%;' mat-header-cell *matHeaderCellDef mat-sort-header> Product Name </th>
                <td mat-cell *matCellDef="let row"> {{row.name}} </td>
            </ng-container>

            <!-- Progress Column -->
            <ng-container matColumnDef="brand">
                <th style='width: 8%;' mat-header-cell *matHeaderCellDef mat-sort-header> Brand </th>
                <td mat-cell *matCellDef="let row"> {{row.brand}} </td>
            </ng-container>

            <!-- Color Column -->
            <ng-container matColumnDef="status">
                <th style='width: 6%;' mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let row" [style.color]="row.color"> {{row.isActive}} </td>
            </ng-container>

            <!-- Color Column -->
            <ng-container matColumnDef="created">
                <th style='width: 8%;' mat-header-cell *matHeaderCellDef mat-sort-header> Create Date</th>
                <td mat-cell *matCellDef="let row"> {{row.createdAt}} </td>
            </ng-container>

            <!-- Color Column -->
            <ng-container matColumnDef="actions">
                <th style='width: 20%;' mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                <td mat-cell  id='actions' *matCellDef="let row">
                    <button *ngIf="user.role == 'admin'" mat-raised-button style='background: #000;' (click)="openDialogWithId(row._id)" class="btn-xs" color="warn"><mat-icon class="sidenav-icon">create</mat-icon>Edit</button>
                  <!--<div *ngIf="user.role == 'admin'">-->
                    <button *ngIf="user.role == 'admin'" mat-raised-button class="btn-xs" (click)='delete(row._id)' color="warn"><mat-icon class="sidenav-icon">delete_sweep</mat-icon>Delete</button>
                  <!--</div>-->
                    <!--<div *ngIf="">-->
                        <button *ngIf="user.role == 'admin' && row.isActive; else templateName" mat-raised-button class="btn-xs" (click)='changeCompanystatus(row._id,row.isActive)' color="warn"><mat-icon class="sidenav-icon">cancel</mat-icon>Deactivate</button>
                    <!--</div>-->

                    <ng-template #templateName>
                        <button *ngIf="user.role == 'admin'" mat-raised-button class="btn-xs" style='background: #000;' (click)='changeCompanystatus(row._id,row.isActive)' color="warn"><mat-icon class="sidenav-icon">cancel</mat-icon>activate</button>
                    </ng-template>

                 </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 50, 25, 100]" showFirstLastButtons></mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
