<h1 mat-dialog-title>New Entree to {{ data.name }}</h1>

<div mat-dialog-content>
  <mat-form-field>
    <input matInput type="number" [(ngModel)]="data.quantity" placeholder="Quantity">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">No Thanks</button>
  <button id="add" mat-button [mat-dialog-close]="data" cdkFocusInitial>Add</button>
</div>
