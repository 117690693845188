<h1>Company Management</h1>
<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h2>Company List</h2><button *ngIf="user.role == 'admin'" style='background: #000;' mat-raised-button class="addcomp" (click)="openDialog()" color="warn"><mat-icon class="sidenav-icon">add_box</mat-icon> Add Company</button>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
    </mat-form-field>

    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>

            <!-- ID Column -->
            <ng-container matColumnDef="company">
                <th style='width: 26%;' mat-header-cell *matHeaderCellDef mat-sort-header> Company </th>
                <td mat-cell *matCellDef="let row"> {{row.name}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="customerno">
                <th style='width: 10%;' mat-header-cell *matHeaderCellDef mat-sort-header> CustomerNo </th>
                <td mat-cell *matCellDef="let row"> {{row.phoneNum}} </td>
            </ng-container>

            <!-- Progress Column -->
            <ng-container matColumnDef="email">
                <th style='width: 27%;' mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
                <td mat-cell *matCellDef="let row"> {{row.email}} </td>
            </ng-container>

            <!-- Color Column -->
            <ng-container matColumnDef="status">
                <th style='width: 6%;' mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let row" [style.color]="row.color"> {{row.status}} </td>
            </ng-container>

            <!-- Color Column -->
            <ng-container matColumnDef="created">
                <th style='width: 8%;' mat-header-cell *matHeaderCellDef mat-sort-header> Created On</th>
                <td mat-cell *matCellDef="let row"> {{row.created}} </td>
            </ng-container>

            <!-- Color Column -->
            <ng-container matColumnDef="actions">
                <th style='width: 23%;' mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                <td mat-cell id='actions' *matCellDef="let row">
                    <button *ngIf="user.role == 'admin'" mat-raised-button class="btn-xs" style='background: #000;' (click)="openDialogWithId(row._id)" color="warn"><mat-icon class="sidenav-icon">create</mat-icon>Edit</button>
                  <!--<div >-->
                    <button *ngIf="user.role == 'admin'" mat-raised-button class="btn-xs" (click)='delete(row._id)' color="warn"><mat-icon class="sidenav-icon">delete_sweep</mat-icon>Delete</button>
                  <!--</div>-->
                    <!--<div >-->
                        <button *ngIf="user.role == 'admin' && row.status=='active'; else templateName" mat-raised-button class="btn-xs" (click)='changeCompanystatus(row._id,row.status)' color="warn"><mat-icon class="sidenav-icon">cancel</mat-icon>Deactivate</button>
                    <!--</div>-->

                    <ng-template #templateName>
                        <button *ngIf="user.role == 'admin'" mat-raised-button class="btn-xs" style='background: #000;' (click)='changeCompanystatus(row._id,row.status)' color="warn"><mat-icon class="sidenav-icon">cancel</mat-icon>activate</button>
                    </ng-template>
                 </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 50, 25, 100]" showFirstLastButtons></mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
