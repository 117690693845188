import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './shared/guard/auth.guard';

import {ModuleMapLoaderModule} from '@nguniversal/module-map-ngfactory-loader';

import {LoginModule} from './login/login.module';
import {LayoutModule} from './layout/layout.module';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => LayoutModule,
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => LoginModule
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), ModuleMapLoaderModule],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {
}
