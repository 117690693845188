import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';

import {DialogOverviewComponent} from './dialog-overview/dialog-overview.component';
import {RapportService} from './rapport.service';
import {Rapport} from './rapport.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-grid',
  templateUrl: './rapport.component.html',
  styleUrls: ['./rapport.component.scss']
})
export class RapportComponent implements OnInit {
  displayedColumn = ['date', 'file', 'actions'];
  dataSource: MatTableDataSource<Rapport>;
  rapports: Rapport[] = [];
  warehouses: any = [];
  forms: any = [];
  user = JSON.parse(sessionStorage.getItem('user'));

  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private rapportService: RapportService, public dialog: MatDialog) {

    this.rapportService
      .getWarehouses()
      .subscribe((data) => {
        this.warehouses = data;
      });

    // this.fetchRapports();

    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource(this.rapports);

    // Assign the data to the data source for the table to render
    // this.dataSource = new MatTableDataSource(this.rapports);
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }


  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  fetchRapports() {

    Swal.fire({
      title: 'Chargement',
      html: 'It will closed',
      onOpen: () => {
        Swal.showLoading();
        this.rapportService
          .getRapports()
          .subscribe((data: Rapport[]) => {
            this.rapports = data;
            this.dataSource = new MatTableDataSource(this.rapports);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            Swal.close();
          });
      },
      onClose: () => {
        // clearInterval(timerInterval)
      }
    }).then((result) => {
      if (
        // Read more about handling dismissals
        result.dismiss === Swal.DismissReason.timer
      ) {
        console.log('I was closed by the timer');
      }
    });
  }

  openDialog(): void {
    const dialogDetail = this.dialog.open(DialogOverviewComponent, {
      width: '850px',
      data: {date: null, warehouses: this.warehouses, summary: false, checked: false, warehouse: null, datefin: null, stock: false},
    });

    const Toast = this.Toast;

    dialogDetail.afterClosed().subscribe(result => {
      // test et creation de l'order dans la bdd
      if (result.date) {

        if (result.datefin === null) {
          const now = new Date();
          const today = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
          today.setHours(23, 59, 59, 999);
          result.datefin = today;
        } else if (result.datefin) {
          if (result.datefin < result.date) {
            Toast.fire({
              icon: 'error',
              title: 'end date can be higher'
            });
            return;
          }
          result.datefin = new Date(result.datefin.getFullYear(), result.datefin.getMonth(), result.datefin.getDate());
          result.datefin.setHours(23, 59, 59, 999);
        }
        result.date = new Date(result.date.getFullYear(), result.date.getMonth(), result.date.getDate());

        // console.log(result.date);
        // console.log(result.datefin);
        // return;
        if (result.summary) {
          Swal.fire({
            title: 'Chargement',
            html: 'It will closed',
            onOpen: () => {
              Swal.showLoading();
              this.rapportService
                .addSummary(result.date, result.datefin)
                .subscribe((data) => {
                  const responce = JSON.parse(JSON.stringify(data));

                  if (responce.code === 200) {

                    const url = localStorage.getItem('serverRoot') + responce.message;
                    setTimeout(() => {
                      let WindowObjectReference = null; // variable globale

                      function openFFPromotionPopup(uri) {
                        if (WindowObjectReference == null || WindowObjectReference.closed) {
                          /* si le pointeur vers l'objet window n'existe pas, ou s'il existe
                             mais que la fenêtre a été fermée */
                          Swal.close();
                          WindowObjectReference = window.open(uri, '_blank', 'resizable=yes,scrollbars=yes,status=yes');
                          /* alors, création du pointeur. La nouvelle fenêtre sera créée par dessus
                             toute autre fenêtre existante. */
                        } else {
                          WindowObjectReference.focus();
                          Swal.close();
                          /* sinon, la référence à la fenêtre existe et la fenêtre n'a pas été
                             fermée: la fenêtre est peut-être minimisée ou derrière la fenêtre
                             principale. Par conséquent, on peut l'amener par dessus les autres à
                             l'aide de la méthode focus(). Il n'est pas nécessaire de recréer la fenêtre
                             ou de recharger la ressource référencée. */
                          Swal.fire({
                            icon: 'success',
                            title: 'file printed',
                            text: 'votre navigateur ne prend pas en charge notre script clicker sur le lien',
                            footer: '<a href=' + url + '>click</a>'
                          });
                        }
                      }

                      openFFPromotionPopup(url);
                      // window.open(url, '_blank');
                    }, 10000);

                  } else {
                    Toast.fire({
                      icon: 'error',
                      title: 'rapport is not created'
                    });
                  }
                });
            },
            onClose: () => {
              // clearInterval(timerInterval)
            }
          }).then((results) => {
            if (
              // Read more about handling dismissals
              result.dismiss === Swal.DismissReason.timer
            ) {
              console.log('I was closed by the timer');
            }
          });
        }else if (result.stock) {
          Swal.fire({
            title: 'Chargement',
            html: 'It will closed',
            onOpen: () => {
              Swal.showLoading();
              this.rapportService
                .stock(result.date, result.datefin)
                .subscribe((data) => {
                  const responce = JSON.parse(JSON.stringify(data));

                  if (responce.code === 200) {

                    const url = localStorage.getItem('serverRoot') + responce.message;
                    setTimeout(() => {
                      let WindowObjectReference = null; // variable globale

                      function openFFPromotionPopup(uri) {
                        if (WindowObjectReference == null || WindowObjectReference.closed) {
                          /* si le pointeur vers l'objet window n'existe pas, ou s'il existe
                             mais que la fenêtre a été fermée */
                          Swal.close();
                          WindowObjectReference = window.open(uri, '_blank', 'resizable=yes,scrollbars=yes,status=yes');
                          /* alors, création du pointeur. La nouvelle fenêtre sera créée par dessus
                             toute autre fenêtre existante. */
                        } else {
                          WindowObjectReference.focus();
                          Swal.close();
                          /* sinon, la référence à la fenêtre existe et la fenêtre n'a pas été
                             fermée: la fenêtre est peut-être minimisée ou derrière la fenêtre
                             principale. Par conséquent, on peut l'amener par dessus les autres à
                             l'aide de la méthode focus(). Il n'est pas nécessaire de recréer la fenêtre
                             ou de recharger la ressource référencée. */
                          Swal.fire({
                            icon: 'success',
                            title: 'file printed',
                            text: 'votre navigateur ne prend pas en charge notre script clicker sur le lien',
                            footer: '<a href=' + url + '>click</a>'
                          });
                        }
                      }

                      openFFPromotionPopup(url);
                      // window.open(url, '_blank');
                    }, 10000);

                  } else {
                    Toast.fire({
                      icon: 'error',
                      title: 'rapport is not created'
                    });
                  }
                });
            },
            onClose: () => {
              // clearInterval(timerInterval)
            }
          }).then((results) => {
            if (
              // Read more about handling dismissals
              result.dismiss === Swal.DismissReason.timer
            ) {
              console.log('I was closed by the timer');
            }
          });
        } else {
          if (result.checked) {
            if (result.warehouse) {
              Swal.fire({
                title: 'Chargement',
                html: 'It will closed',
                onOpen: () => {
                  Swal.showLoading();
                  this.rapportService
                    .addRapportByWarehouse(result.date, result.datefin, result.warehouse)
                    .subscribe((data) => {
                      const responce = JSON.parse(JSON.stringify(data));

                      if (responce.code === 200) {
                        // console.log(responce.message);
                        // return;
                        const url = localStorage.getItem('serverRoot') + responce.message;
                        setTimeout(() => {
                          let WindowObjectReference = null; // variable globale

                          function openFFPromotionPopup(uri) {
                            if (WindowObjectReference == null || WindowObjectReference.closed) {
                              /* si le pointeur vers l'objet window n'existe pas, ou s'il existe
                                 mais que la fenêtre a été fermée */
                              Swal.close();
                              WindowObjectReference = window.open(uri, '_blank', 'resizable=yes,scrollbars=yes,status=yes');
                              /* alors, création du pointeur. La nouvelle fenêtre sera créée par dessus
                                 toute autre fenêtre existante. */
                            } else {
                              WindowObjectReference.focus();
                              Swal.close();
                              /* sinon, la référence à la fenêtre existe et la fenêtre n'a pas été
                                 fermée: la fenêtre est peut-être minimisée ou derrière la fenêtre
                                 principale. Par conséquent, on peut l'amener par dessus les autres à
                                 l'aide de la méthode focus(). Il n'est pas nécessaire de recréer la fenêtre
                                 ou de recharger la ressource référencée. */
                              Swal.fire({
                                icon: 'success',
                                title: 'file printed',
                                text: 'votre navigateur ne prend pas en charge notre script clicker sur le lien',
                                footer: '<a href=' + url + '>click</a>'
                              });
                            }
                          }

                          openFFPromotionPopup(url);
                          // window.open(url, '_blank');
                        }, 10000);

                      } else {
                        Toast.fire({
                          icon: 'error',
                          title: 'rapport is not created'
                        });
                      }
                    });
                },
                onClose: () => {
                  // clearInterval(timerInterval)
                }
              }).then((results) => {
                if (
                  // Read more about handling dismissals
                  result.dismiss === Swal.DismissReason.timer
                ) {
                  console.log('I was closed by the timer');
                }
              });
            } else {
              Toast.fire({
                icon: 'error',
                title: 'select warehouse please'
              });
            }
          } else {
            Swal.fire({
              title: 'Chargement',
              html: 'It will closed',
              onOpen: () => {
                Swal.showLoading();
                this.rapportService
                  .addRapport(result.date, result.datefin)
                  .subscribe((data) => {
                    const responce = JSON.parse(JSON.stringify(data));

                    if (responce.code === 200) {
                      const url = localStorage.getItem('serverRoot') + responce.message;
                      setTimeout(() => {
                        let WindowObjectReference = null; // variable globale

                        function openFFPromotionPopup(uri) {
                          if (WindowObjectReference == null || WindowObjectReference.closed) {
                            /* si le pointeur vers l'objet window n'existe pas, ou s'il existe
                               mais que la fenêtre a été fermée */
                            Swal.close();
                            WindowObjectReference = window.open(uri, '_blank', 'resizable=yes,scrollbars=yes,status=yes');
                            /* alors, création du pointeur. La nouvelle fenêtre sera créée par dessus
                               toute autre fenêtre existante. */
                          } else {
                            WindowObjectReference.focus();
                            Swal.close();
                            /* sinon, la référence à la fenêtre existe et la fenêtre n'a pas été
                               fermée: la fenêtre est peut-être minimisée ou derrière la fenêtre
                               principale. Par conséquent, on peut l'amener par dessus les autres à
                               l'aide de la méthode focus(). Il n'est pas nécessaire de recréer la fenêtre
                               ou de recharger la ressource référencée. */
                            Swal.fire({
                              icon: 'success',
                              title: 'file printed',
                              text: 'votre navigateur ne prend pas en charge notre script clicker sur le lien',
                              footer: '<a href=' + url + '>click</a>'
                            });
                          }
                        }

                        openFFPromotionPopup(url);
                        // window.open(url, '_blank');
                      }, 10000);

                    } else {
                      Toast.fire({
                        icon: 'error',
                        title: 'rapport is not created'
                      });
                    }
                  });
              },
              onClose: () => {
                // clearInterval(timerInterval)
              }
            }).then((results) => {
              if (
                // Read more about handling dismissals
                result.dismiss === Swal.DismissReason.timer
              ) {
                console.log('I was closed by the timer');
              }
            });
          }
        }

      } else {
        Toast.fire({
          icon: 'error',
          title: 'select date please'
        });
      }


    });
  }


  exports(urlfile: string) {
    const Toast = this.Toast;

    Swal.fire({
      title: 'Chargement',
      html: 'It will closed',
      onOpen: () => {

        Swal.showLoading();
        const url = localStorage.getItem('serverRoot') + '/rapport/' + urlfile;
        let WindowObjectReference = null; // variable globale

        function openFFPromotionPopup(uri) {
          if (WindowObjectReference == null || WindowObjectReference.closed) {
            /* si le pointeur vers l'objet window n'existe pas, ou s'il existe
               mais que la fenêtre a été fermée */
            Swal.close();
            WindowObjectReference = window.open(uri, '_blank', 'resizable=yes,scrollbars=yes,status=yes');
            /* alors, création du pointeur. La nouvelle fenêtre sera créée par dessus
               toute autre fenêtre existante. */
          } else {
            WindowObjectReference.focus();
            Swal.close();
            /* sinon, la référence à la fenêtre existe et la fenêtre n'a pas été
               fermée: la fenêtre est peut-être minimisée ou derrière la fenêtre
               principale. Par conséquent, on peut l'amener par dessus les autres à
               l'aide de la méthode focus(). Il n'est pas nécessaire de recréer la fenêtre
               ou de recharger la ressource référencée. */
            Swal.fire({
              icon: 'success',
              title: 'file printed',
              text: 'votre navigateur ne prend pas en charge notre script clicker sur le lien',
              footer: '<a href=' + url + '>click</a>'
            });
          }
        }

        openFFPromotionPopup(url);
        // window.open(url, '_blank');
      },
      onClose: () => {
        // clearInterval(timerInterval)
      }
    }).then((result) => {
      if (
        // Read more about handling dismissals
        result.dismiss === Swal.DismissReason.timer
      ) {
        console.log('I was closed by the timer');
      }
    });
  }
}
