import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoginserviceService {

  // uri = 'https://twigaprimebackend.herokuapp.com';
  // admin@twiga-prime.cd
  // twigab2b
  uri = localStorage.getItem('serverRoot');

  constructor(private http: HttpClient) {
  }

  logins(userEmail, UserPassword) {

    // const headers = new HttpHeaders();
    // headers.set('Access-Control-Allow-Headers', '*');
    // headers.set('Content-Type', 'application/json; charset=utf-8');
    // headers.set('Content-Type', 'application/json; charset=utf-8');
    // headers.set('Content-Type', 'application/json; charset=utf-8');
    // headers.add('', 'Origin, X-Requested-With, Content-Type, Accept, x-auth');
    const login = {
      email: userEmail,
      password: UserPassword
    };
    return this.http.post(`${this.uri}/auth`, login);
  }
}
