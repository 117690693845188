import {Component, OnInit} from '@angular/core';
import {DashbordService} from './dashbord.service';
import {Produit} from '../produit/produit.model';
import {Company} from '../company/company.model';
import {Order} from '../order/order.model';


@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    places: Array<any> = [];
    produits = 0;
    orders = 0;
    companys = 0;
    constructor(private dashbordService: DashbordService) {
        this.places = [
            {
                imgSrc: 'assets/images/homebg.png',
                motadmin: ''
            },
            {
                imgSrc: 'assets/images/card-2.jpg',
                motadmin: 'Welcome Admin'
            }
        ];

        this.dashbordService
            .getProduits()
            .subscribe((data: Produit[]) => {
                this.produits = data.length;
            });

        this.dashbordService
            .getCompanys()
            .subscribe((data: Company[]) => {
                this.companys = data.length;
            });

        this.dashbordService
            .getOrders()
            .subscribe((data: Order[]) => {
                this.orders = data.length;
            });
    }

    ngOnInit() {
    }
}

