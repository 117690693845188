<h1>Agent Manager</h1>
<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h2>Agent Manager List</h2>
      <button style='background: #000;' mat-raised-button (click)="openDialog()" class="addcomp" color="warn">
        <mat-icon class="sidenav-icon">add_box</mat-icon>
        Add Agent Manager
      </button>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
    </mat-form-field>

    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th style='width: 12%;' mat-header-cell *matHeaderCellDef mat-sort-header> First Name</th>
          <td mat-cell *matCellDef="let row"> {{row.name}}</td>
        </ng-container>

        <!-- Progress Column -->
        <ng-container matColumnDef="email">
          <th style='width: 12%;' mat-header-cell *matHeaderCellDef mat-sort-header> Last Name</th>
          <td mat-cell *matCellDef="let row"> {{row.email}}</td>
        </ng-container>


        <!-- Color Column -->
        <ng-container matColumnDef="role">
          <th style='width: 12%;' mat-header-cell *matHeaderCellDef mat-sort-header> Phone Number</th>
          <td mat-cell *matCellDef="let row"> {{row.role}}</td>
        </ng-container>

        <!-- Color Column -->
        <ng-container matColumnDef="createdAt">
          <th style='width: 10%;' mat-header-cell *matHeaderCellDef mat-sort-header> Created At</th>
          <td mat-cell *matCellDef="let row"> {{row.createdAt}}</td>
        </ng-container>

        <!--Color Column-->
        <ng-container matColumnDef="actions">
          <th style='width: 20%;' mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
          <td mat-cell id='actions' *matCellDef="let row">
            <button mat-raised-button style='background: #000;' class="btn-xs" (click)="openDialogWithId(row._id)"
                    color="warn">
              <mat-icon class="sidenav-icon">create</mat-icon>
              Edit
            </button>

            <!--<div>-->
              <button mat-raised-button class="btn-xs" (click)='changeAgent(row._id)' color="warn">
                <mat-icon class="sidenav-icon">cancel</mat-icon>
                Delete
              </button>
            <!--</div>-->

          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 50, 25, 100]" showFirstLastButtons></mat-paginator>
    </div>

  </mat-card-content>
</mat-card>
