import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

// import { RequestOptions,Headers } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class AuditService {
  uri = localStorage.getItem('serverRoot');
  user = JSON.parse(sessionStorage.getItem('user'));

  constructor(private http: HttpClient) {
  }

  getOrders() {
    return this.http.get(`${this.uri}/admins/${this.user._id}/audit`);
  }


}
