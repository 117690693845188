import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';

import {DialogOverviewComponent} from './dialog-overview/dialog-overview.component';
import {ViewdetailsComponent} from './viewdetails/viewdetails.component';
import {OrderseserviceService} from './orderseservice.service';
import {Order} from './order.model';
import {Produit} from '../produit/produit.model';
import {Company} from '../company/company.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-grid',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {
  displayedColumn = ['ordernumber', 'companyname', 'fulfillmentoption', 'fulfillmentdate', 'end date', 'created', 'orderstatuts', 'actions'];
  dataSource: MatTableDataSource<Order>;
  orders: Order[] = [];
  produits: string[] = [];
  companys: string[] = [];
  archive = false;
  forms: any = [];
  user = JSON.parse(sessionStorage.getItem('user'));

  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private orderseserviceService: OrderseserviceService, public dialog: MatDialog) {

    this.orderseserviceService
      .getProduits()
      .subscribe((data: Produit[]) => {
        const pass: string[] = [];
        data.forEach((value) => {
          pass.push(value.productNo);
        });

        this.produits = pass;
      });

    this.orderseserviceService
      .getCompanys()
      .subscribe((data: Company[]) => {
        const pass: string[] = [];
        data.forEach((value) => {
          pass.push(value.name);
        });

        this.companys = pass;
      });

    this.fetchOrders();

    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource(this.orders);

    // Assign the data to the data source for the table to render
    // this.dataSource = new MatTableDataSource(this.orders);
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  fetchOrders() {

    this.archive = false;
    Swal.fire({
      title: 'Chargement',
      html: 'It will closed',
      onOpen: () => {
        Swal.showLoading();
        this.orderseserviceService
          .getOrders()
          .subscribe((data: Order[]) => {
            this.orders = data;
            this.dataSource = new MatTableDataSource(this.orders);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            Swal.close();
          });
      },
      onClose: () => {
        // clearInterval(timerInterval)
      }
    }).then((result) => {
      if (
        // Read more about handling dismissals
        result.dismiss === Swal.DismissReason.timer
      ) {
        console.log('I was closed by the timer');
      }
    });
  }

  fetchOrder() {
    this.archive = true;

    Swal.fire({
      title: 'Chargement',
      html: 'It will closed',
      onOpen: () => {
        Swal.showLoading();
        this.orderseserviceService
          .getOrdersArchive()
          .subscribe((data: Order[]) => {
            this.orders = data;
            this.dataSource = new MatTableDataSource(this.orders);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            Swal.close();
          });
      },
      onClose: () => {
        // clearInterval(timerInterval)
      }
    }).then((result) => {
      if (
        // Read more about handling dismissals
        result.dismiss === Swal.DismissReason.timer
      ) {
        console.log('I was closed by the timer');
      }
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getarchive(): void {
    this.fetchOrder();

    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource(this.orders);
  }

  openDialog(): void {
    const dialogDetail = this.dialog.open(DialogOverviewComponent, {
      width: '850px',
      data: {companys: this.companys, produits: this.produits}
    });

    const Toast = this.Toast;

    dialogDetail.afterClosed().subscribe(result => {
      // test et creation de l'order dans la bdd

      // result est l'objet qui comporte tous les champs
      if ('fulfillmentoption' in result && 'date' in result && 'Companies' in result) {
        if ('fileLoad' in result) {
          this.orderseserviceService
            .addOrder(result.Companies, result.fulfillmentoption, result.date, result.datefin, result.fileLoad)
            .subscribe((data) => {
              const responce = JSON.parse(JSON.stringify(data));
              console.log(responce);
              if (responce.code === 200) {

                this.fetchOrders();
                Toast.fire({
                  icon: 'success',
                  title: 'order added'
                });

              } else {
                Toast.fire({
                  icon: 'error',
                  title: 'order not add'
                });
              }
            });
        } else {
          if ('firstname' in result && 'lastname' in result && 'employeid' in result && 'location' in result && 'departement' in result) {
            // pacourire le tableau de produit tester si une cle existe recuperer son contenu
            const produ = [];
            this.produits.forEach((value) => {
              if (value in result) {
                produ.push({
                  product_name: value,
                  product_code: value,
                  total_count: result[value]
                });
              } else {
                produ.push({
                  product_name: '',
                  product_code: '',
                  total_count: ''
                });
              }
            });

            const client = {
              firstname: result.firstname,
              lastname: result.lastname,
              employeid: result.employeid,
              location: result.location,
              departement: result.departement
            };

            this.orderseserviceService
              .addOrderForOn(result.Companies, result.fulfillmentoption, result.date, result.datefin, client, produ)
              .subscribe((data) => {
                const responce = JSON.parse(JSON.stringify(data));
                if (responce.code === 200) {
                  Toast.fire({
                    icon: 'success',
                    title: 'order added'
                  });
                  this.fetchOrders();
                } else {
                  Toast.fire({
                    icon: 'error',
                    title: 'order not add'
                  });
                }
              });
          }
        }
        this.forms = result;
      } else {
        Toast.fire({
          icon: 'error',
          title: 'remplisez tout les champs'
        });
      }

    });
  }

  openDialogDetails(idDialog: string): void {
    const dialogDetail = this.dialog.open(ViewdetailsComponent, {
      width: '850px',
      data: {id: idDialog}
    });

    dialogDetail.afterClosed().subscribe(result => {
      // test et creation de l'order dans la bdd

      // result est l'objet qui comporte tous les champs
    });
  }

  export(id: string) {
    const Toast = this.Toast;

    Swal.fire({
      title: 'Export',
      text: 'Export an order',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Export all Order',
      cancelButtonText: 'Export by department!',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

        Swal.fire({
          title: 'Chargement',
          html: 'It will closed',
          onOpen: () => {
            Swal.showLoading();
            this.orderseserviceService
              .exportOrder(id)
              .subscribe((data) => {
                const responce = JSON.parse(JSON.stringify(data));
                if (responce.code === 200) {
                  const url = localStorage.getItem('serverRoot') + responce.message;
                  setTimeout(() => {
                    let WindowObjectReference = null; // variable globale

                    function openFFPromotionPopup(uri) {
                      if (WindowObjectReference == null || WindowObjectReference.closed) {
                        /* si le pointeur vers l'objet window n'existe pas, ou s'il existe
                           mais que la fenêtre a été fermée */
                        Swal.close();
                        WindowObjectReference = window.open(uri, '_blank', 'resizable=yes,scrollbars=yes,status=yes');
                        /* alors, création du pointeur. La nouvelle fenêtre sera créée par dessus
                           toute autre fenêtre existante. */
                      } else {
                        WindowObjectReference.focus();
                        Swal.close();
                        /* sinon, la référence à la fenêtre existe et la fenêtre n'a pas été
                           fermée: la fenêtre est peut-être minimisée ou derrière la fenêtre
                           principale. Par conséquent, on peut l'amener par dessus les autres à
                           l'aide de la méthode focus(). Il n'est pas nécessaire de recréer la fenêtre
                           ou de recharger la ressource référencée. */
                        Swal.fire({
                          icon: 'success',
                          title: 'file printed',
                          text: 'votre navigateur ne prend pas en charge notre script clicker sur le lien',
                          footer: '<a href=' + url + '>click</a>'
                        });
                      }
                    }

                    openFFPromotionPopup(url);
                    // window.open(url, '_blank');
                  }, 10000);

                  // window.location.href=url;
                } else {
                  Toast.fire({
                    icon: 'error',
                    title: 'document not print'
                  });
                }
              });
          },
          onClose: () => {
            // clearInterval(timerInterval)
          }
        }).then((result) => {
          if (
            // Read more about handling dismissals
            result.dismiss === Swal.DismissReason.timer
          ) {
            console.log('I was closed by the timer');
          }
        });
      } else if (
        // Read more about handling dismissals
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire({
          title: 'Department',
          input: 'text',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'export it'
        }).then((result) => {
          if (result.value) {
            Swal.fire({
              title: 'Chargement',
              html: 'It will closed',
              onOpen: () => {
                Swal.showLoading();
                this.orderseserviceService
                  .exportOrderBydep(id, result.value)
                  .subscribe((data) => {
                    const responce = JSON.parse(JSON.stringify(data));
                    if (responce.code === 200) {
                      const url = localStorage.getItem('serverRoot') + responce.message;
                      setTimeout(() => {
                        let WindowObjectReference = null; // variable globale

                        function openFFPromotionPopup(uri) {
                          if (WindowObjectReference == null || WindowObjectReference.closed) {
                            /* si le pointeur vers l'objet window n'existe pas, ou s'il existe
                               mais que la fenêtre a été fermée */
                            Swal.close();
                            WindowObjectReference = window.open(uri, '_blank', 'resizable=yes,scrollbars=yes,status=yes');
                            /* alors, création du pointeur. La nouvelle fenêtre sera créée par dessus
                               toute autre fenêtre existante. */
                          } else {
                            WindowObjectReference.focus();
                            Swal.close();
                            /* sinon, la référence à la fenêtre existe et la fenêtre n'a pas été
                               fermée: la fenêtre est peut-être minimisée ou derrière la fenêtre
                               principale. Par conséquent, on peut l'amener par dessus les autres à
                               l'aide de la méthode focus(). Il n'est pas nécessaire de recréer la fenêtre
                               ou de recharger la ressource référencée. */
                            Swal.fire({
                              icon: 'success',
                              title: 'file printed',
                              text: 'votre navigateur ne prend pas en charge notre script clicker sur le lien',
                              footer: '<a href=' + url + '>click</a>'
                            });
                          }
                        }

                        openFFPromotionPopup(url);
                        // window.open(url, '_blank');
                      }, 10000);

                      // window.location.href=url;
                    } else {
                      Toast.fire({
                        icon: 'error',
                        title: 'document not print'
                      });
                    }
                  });
              },
              onClose: () => {
                // clearInterval(timerInterval)
              }
            }).then((result) => {
              if (
                // Read more about handling dismissals
                result.dismiss === Swal.DismissReason.timer
              ) {
                console.log('I was closed by the timer');
              }
            });
          }
        });
      }
    });
  }

  printOrders(idorder: string) {
    const Toast = this.Toast;
    Swal.fire({
      title: 'Print',
      text: 'print an order',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'print all Order',
      cancelButtonText: 'print by department!',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {

        Swal.fire({
          title: 'Chargement',
          html: 'It will closed',
          onOpen: () => {
            Swal.showLoading();
            this.orderseserviceService
              .printOrder(idorder)
              .subscribe((data) => {
                const responce = JSON.parse(JSON.stringify(data));
                if (responce.code === 200) {
                  const url = localStorage.getItem('serverRoot') + responce.message;
                  setTimeout(() => {
                    let WindowObjectReference = null; // variable globale

                    function openFFPromotionPopup(uri) {
                      if (WindowObjectReference == null || WindowObjectReference.closed) {
                        /* si le pointeur vers l'objet window n'existe pas, ou s'il existe
                           mais que la fenêtre a été fermée */
                        WindowObjectReference = window.open(uri, '_blank', 'resizable=yes,scrollbars=yes,status=yes');
                        /* alors, création du pointeur. La nouvelle fenêtre sera créée par dessus
                           toute autre fenêtre existante. */
                      } else {
                        WindowObjectReference.focus();
                        /* sinon, la référence à la fenêtre existe et la fenêtre n'a pas été
                           fermée: la fenêtre est peut-être minimisée ou derrière la fenêtre
                           principale. Par conséquent, on peut l'amener par dessus les autres à
                           l'aide de la méthode focus(). Il n'est pas nécessaire de recréer la fenêtre
                           ou de recharger la ressource référencée. */
                        Swal.fire({
                          icon: 'success',
                          title: 'file printed',
                          text: 'votre navigateur ne prend pas en charge notre script clicker sur le lien',
                          footer: '<a href=' + url + '>click</a>'
                        });
                      }
                    }

                    openFFPromotionPopup(url);
                    // window.open(url, '_blank');
                  }, 2000);

                  // window.location.href=url;
                } else {
                  Toast.fire({
                    icon: 'error',
                    title: 'document not print'
                  });
                }
                Swal.close();
              });
          },
          onClose: () => {
            // clearInterval(timerInterval)
          }
        }).then((result) => {
          if (
            // Read more about handling dismissals
            result.dismiss === Swal.DismissReason.timer
          ) {
            console.log('I was closed by the timer');
          }
        });
      } else if (
        // Read more about handling dismissals
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire({
          title: 'Department',
          input: 'text',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Print it'
        }).then((result) => {
          if (result.value) {

            Swal.fire({
              title: 'Chargement',
              html: 'It will closed',
              onOpen: () => {
                Swal.showLoading();
                this.orderseserviceService
                  .printOrderByDep(idorder, result.value)
                  .subscribe((data) => {
                    const responce = JSON.parse(JSON.stringify(data));
                    if (responce.code === 200) {
                      const url = localStorage.getItem('serverRoot') + responce.message;
                      setTimeout(() => {
                        let WindowObjectReference = null; // variable globale

                        function openFFPromotionPopup(uri) {
                          if (WindowObjectReference == null || WindowObjectReference.closed) {
                            /* si le pointeur vers l'objet window n'existe pas, ou s'il existe
                               mais que la fenêtre a été fermée */
                            WindowObjectReference = window.open(uri, '_blank', 'resizable=yes,scrollbars=yes,status=yes');
                            /* alors, création du pointeur. La nouvelle fenêtre sera créée par dessus
                               toute autre fenêtre existante. */
                          } else {
                            WindowObjectReference.focus();
                            /* sinon, la référence à la fenêtre existe et la fenêtre n'a pas été
                               fermée: la fenêtre est peut-être minimisée ou derrière la fenêtre
                               principale. Par conséquent, on peut l'amener par dessus les autres à
                               l'aide de la méthode focus(). Il n'est pas nécessaire de recréer la fenêtre
                               ou de recharger la ressource référencée. */
                            Swal.fire({
                              icon: 'success',
                              title: 'file printed',
                              text: 'votre navigateur ne prend pas en charge notre script clicker sur le lien',
                              footer: '<a href=' + url + '>click</a>'
                            });
                          }
                        }

                        openFFPromotionPopup(url);
                        // window.open(url, '_blank');
                      }, 2000);

                      // window.location.href=url;
                    } else {
                      Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'document not print',
                        showConfirmButton: false,
                        timer: 3000
                      });
                    }
                    Swal.close();
                  });
              },
              onClose: () => {
                // clearInterval(timerInterval)
              }
            }).then((result) => {
              if (
                // Read more about handling dismissals
                result.dismiss === Swal.DismissReason.timer
              ) {
                console.log('I was closed by the timer');
              }
            });
          }
        });
      }
    });
  }

  delete(id: string): void {

    const Toast = this.Toast;
    Swal.fire({
      title: 'une commande ne peut pas être archiver sans motif !!',
      input: 'text',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        this.orderseserviceService
          .deleteOrder(id, result.value)
          .subscribe((data) => {
            const responce = JSON.parse(JSON.stringify(data));
            if (responce.code === 200) {
              Toast.fire({
                icon: 'success',
                title: responce.message
              });

              this.fetchOrders();
            } else {
              Toast.fire({
                icon: 'error',
                title: responce.message
              });
            }
          });
      }else {
        Toast.fire({
          icon: 'error',
          title: 'une commande ne peut pas être archiver sans motif !!'
        });
      }
    });
  }

  changeWarehouse(id: string, status: string): void {
    const Toast = this.Toast;

    Swal.fire({
      title: 'une commande ne peut pas être annulée sans motif !!',
      input: 'text',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        if (result) {
          let newStatus;
          if (status === 'Approved') {
            newStatus = 'Reject';
          } else {
            newStatus = 'Approved';
          }
          this.orderseserviceService
            .changeOrderstatus(id, newStatus, result.value)
            .subscribe((data) => {
              const responce = JSON.parse(JSON.stringify(data));
              if (responce.code === 200) {
                Toast.fire({
                  icon: 'success',
                  title: responce.message
                });
                this.fetchOrders();
              } else {
                Toast.fire({
                  icon: 'error',
                  title: responce.message
                });
              }
            });
        }
      }else {
        Toast.fire({
          icon: 'error',
          title: 'une commande ne peut pas être annulée sans motif !!'
        });
      }
    });
  }

  fetchproduit() {
    this.orderseserviceService
      .getOrders()
      .subscribe((data: Order[]) => {
        this.orders = data;
        console.log('Data requested ... ');
        console.log(this.orders);
      });
  }
}
