import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PrduitserviceService {
  uri = localStorage.getItem('serverRoot');
  user = JSON.parse(sessionStorage.getItem('user'));

  constructor(private http: HttpClient) {
  }

  addProduit(productno, productname, brand) {
    const produit = {
      productNo: productno,
      name: productname,
      brand: brand
    };
    return this.http.post(`${this.uri}/admins/${this.user._id}/addProduct`, produit);
  }

  getProduits() {
    return this.http.get(`${this.uri}/admins/${this.user._id}/products`);
  }

  getProduitById(id) {
    return this.http.get(`${this.uri}/product/${id}`);
  }

  updateProduit(id, productno, productname, brand) {
    const produit = {
      productNo: productno,
      name: productname,
      brand: brand
    };
    return this.http.put(`${this.uri}/admins/${this.user._id}/updateProduct/${id}`, produit);
  }

  deleteProduit(id) {

    const produit = {
      isActive: true
    };
    return this.http.put(`${this.uri}/admins/${this.user._id}/deleteProduct/${id}`, produit);
  }

  changeProduitstatus(id, status) {

    const produit = {
      isActive: status
    };
    return this.http.put(`${this.uri}/admins/${this.user._id}/updateProduct/${id}`, produit);
  }
}
