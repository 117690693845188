import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

import {DialogOverviewComponent} from './dialog-overview/dialog-overview.component';
import {CompanyseserviceService} from './companyseservice.service';
import {Company} from './company.model';
import {DialogAskComponent} from './dialog-ask/dialog-ask.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-tables',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {
  displayedColumns = ['company', 'customerno', 'email', 'status', 'created', 'actions'];

  dataSource: MatTableDataSource<Company>;
  company: string;
  email: string;
  CustomerNumber: string;
  Password: string;
  ConfirmPassword: string;
  companys: Company[] = [];
  user = JSON.parse(sessionStorage.getItem('user'));

  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });


  constructor(private companyseserviceService: CompanyseserviceService, public dialog: MatDialog) {
    this.fetchproduit();
    this.dataSource = new MatTableDataSource(this.companys);
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogOverviewComponent, {
      width: '450px',
      data: {
        company: this.company,
        email: this.email,
        CustomerNumber: this.CustomerNumber,
        Password: '',
        ConfirmPassword: ''
      }
    });

    const Toast = this.Toast;

    dialogRef.afterClosed().subscribe(result => {
      // test et creation de la company dans la bdd
      // result est l'objet qui comporte tous les champs
      if (result) {
        if (result.Password === result.ConfirmPassword) {

          this.companyseserviceService
            .addCompany(result.company, result.CustomerNumber, result.email, result.Password, result.ConfirmPassword)
            .subscribe((data: Company[]) => {
              const responce = JSON.parse(JSON.stringify(data));
              if (responce.code === 200) {
                Toast.fire({
                  icon: 'success',
                  title: responce.message
                });

                this.fetchproduit();
              } else {
                Toast.fire({
                  icon: 'error',
                  title: responce.message
                });
              }

            });
        }
      } else {
        Toast.fire({
          icon: 'error',
          title: 'password are not the same',
        });
      }

    });
  }

  openDialogWithId(idDialog: string): void {
    let company;
    this.companys.forEach((value) => {
      if (idDialog === value._id) {
        company = value;
      }
    });

    const dialogRef = this.dialog.open(DialogOverviewComponent, {
      width: '450px',
      data: {
        id: idDialog,
        company: company.name,
        email: company.email,
        CustomerNumber: company.phoneNum,
        Password: '',
        ConfirmPassword: ''
      }
    });

    const Toast = this.Toast;

    dialogRef.afterClosed().subscribe(result => {
      // test et moddification a la bdd
      // result est l'objet qui comporte tous les champs
      if (result) {
        if (result.Password === result.ConfirmPassword) {

          this.companyseserviceService
            .updateCompany(idDialog, result.company, result.CustomerNumber, result.email, result.Password)
            .subscribe((data: Company[]) => {
              const responce = JSON.parse(JSON.stringify(data));
              if (responce.code === 200) {
                Toast.fire({
                  icon: 'success',
                  title: responce.message
                });

                this.fetchproduit();
              } else {
                Toast.fire({
                  icon: 'error',
                  title: responce.message
                });
              }
            });
        } else {
          Toast.fire({
            icon: 'error',
            title: 'password are not the same',
          });
        }
      }
    });
  }

  fetchproduit() {
    Swal.fire({
      title: 'Chargement',
      html: 'It will closed',
      onOpen: () => {
        Swal.showLoading();
        this.companyseserviceService
          .getCompanys()
          .subscribe((data: Company[]) => {
            this.companys = data;
            this.dataSource = new MatTableDataSource(data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            Swal.close();
          });

      },
      onClose: () => {
        // clearInterval(timerInterval)
      }
    }).then((result) => {
      if (
        // Read more about handling dismissals
        result.dismiss === Swal.DismissReason.timer
      ) {
        console.log('I was closed by the timer');
      }
    });
  }

  delete(id: string): void {
    const dialogRef = this.dialog.open(DialogAskComponent, {
      width: '350px',
      data: {title: 'delete'}
    });

    const Toast = this.Toast;

    dialogRef.afterClosed().subscribe(result => {
      // test et moddification a la bdd
      // result est l'objet qui comporte tous les champs
      if (result) {
        this.companyseserviceService
          .deleteCompany(id)
          .subscribe((data: Company[]) => {
            const responce = JSON.parse(JSON.stringify(data));
            if (responce.code === 200) {
              Toast.fire({
                icon: 'success',
                title: responce.message
              });

              this.fetchproduit();
            } else {
              Toast.fire({
                icon: 'error',
                title: responce.message
              });
            }
          });
      }
    });
  }

  changeCompanystatus(id: string, status: string): void {
    const dialogRef = this.dialog.open(DialogAskComponent, {
      width: '350px',
      data: {title: 'change status'}
    });

    const Toast = this.Toast;

    dialogRef.afterClosed().subscribe(result => {
      // test et moddification a la bdd
      // result est l'objet qui comporte tous les champs
      if (result) {
        let newStatus;
        if (status === 'active') {
          newStatus = 'Deactive';
        } else {
          newStatus = 'active';
        }
        this.companyseserviceService
          .changeCompanystatus(id, newStatus)
          .subscribe((data: Company[]) => {
            const responce = JSON.parse(JSON.stringify(data));
            if (responce.code === 200) {
              Toast.fire({
                icon: 'success',
                title: responce.message
              });

              this.fetchproduit();
            } else {
              Toast.fire({
                icon: 'error',
                title: responce.message
              });
            }
          });
      }
    });
  }

}
