import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CompanyseserviceService {

  uri = localStorage.getItem('serverRoot');
  user = JSON.parse(sessionStorage.getItem('user'));

  constructor(private http: HttpClient) {
  }

  addCompany(company, customerno, email, password, password2) {
    if (password !== password2) {
      alert('ecrire le meme password svp!!');
    }
    else {
      const companys = {
        name: company,
        phoneNum: customerno,
        email: email,
        passWord: password
      };
      console.log(companys);
      return this.http.post(`${this.uri}/admins/${this.user._id}/addCompany`, companys);
    }
  }

  getCompanys() {
    return this.http.get(`${this.uri}/admins/${this.user._id}/companys`);
  }

  getCompanyById(id) {
    return this.http.get(`${this.uri}/company/${id}`);
  }

  updateCompany(id, company, customerno, email, password) {

    if (password.length > 0) {
      const companys = {
        name: company,
        phoneNum: customerno,
        email: email,
        passWord: password
      };
      return this.http.put(`${this.uri}/admins/${this.user._id}/updateCompany/${id}`, companys);
    } else {
      const companys = {
        name: company,
        phoneNum: customerno,
        email: email,
      };
      return this.http.put(`${this.uri}/admins/${this.user._id}/updateCompany/${id}`, companys);
    }
  }

  deleteCompany(id) {
    const company = {
      status: true
    };
    return this.http.put(`${this.uri}/admins/${this.user._id}/deleteCompany/${id}`, company);
  }

  changeCompanystatus(id, status) {
    const company = {
      status: status
    };
    return this.http.put(`${this.uri}/admins/${this.user._id}/updateCompany/${id}`, company);
  }
}
