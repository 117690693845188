<h1>Manager Warehouse</h1>
<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h2>Warehouse List</h2><button *ngIf="user.role == 'admin'" style='background: #000;' mat-raised-button (click)="openDialog()" class="addcomp" color="warn"><mat-icon class="sidenav-icon">add_box</mat-icon> Add Warehouse</button>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
    </mat-form-field>

    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort>

        <!-- ID Column -->
        <ng-container matColumnDef="code">
          <th style='width: 6%;' mat-header-cell *matHeaderCellDef mat-sort-header> Code </th>
          <td mat-cell *matCellDef="let row"> {{row.warehouse.code}} </td>
        </ng-container>

        <!-- ID Column -->
        <ng-container matColumnDef="Name">
          <th style='width: 6%;' mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let row"> {{row.warehouse.name}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="Location">
          <th style='width: 12%;' mat-header-cell *matHeaderCellDef mat-sort-header> Location </th>
          <td mat-cell *matCellDef="let row"> {{row.warehouse.location}} </td>
        </ng-container>

        <!-- Progress Column -->
        <ng-container matColumnDef="Manager">
          <th style='width: 12%;' mat-header-cell *matHeaderCellDef mat-sort-header> Manager </th>
          <td mat-cell *matCellDef="let row"> {{row.warehousemanager.LastName}} {{row.warehousemanager.FirstName}} </td>
        </ng-container>

        <!-- Color Column -->
        <ng-container matColumnDef="actions">
          <th style='width: 20%;' mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
          <td mat-cell id='actions' *matCellDef="let row">
            <button *ngIf="user.role == 'admin'" mat-raised-button style='background: #000;' class="btn-xs" (click)="openDialogWithId(row.warehouse._id)" color="warn"><mat-icon class="sidenav-icon">create</mat-icon>Edit</button>
            <!--<div >-->
              <button *ngIf="user.role == 'admin'" mat-raised-button class="btn-xs" (click)='delete(row.warehouse._id)' color="warn"><mat-icon class="sidenav-icon">delete_sweep</mat-icon>Delete</button>
            <!--</div>-->
            <button mat-raised-button class="btn-xs" (click)="redirect(row.warehouse._id)" color="warn" style='background: rgb(254, 192, 13);'><mat-icon class="sidenav-icon">visibility</mat-icon><b>View details</b></button>




            <!--<div >-->
              <button *ngIf="row.warehousemanager.LastName && user.role == 'admin'; else templateName" mat-raised-button class="btn-xs" (click)="deletewarehouse(row.warehouse._id)" color="warn"><mat-icon class="sidenav-icon">delete_sweep</mat-icon><b>delete Manager</b></button>
            <!--</div>-->

            <ng-template #templateName>
              <button *ngIf="user.role == 'admin'" mat-raised-button class="btn-xs" (click)="addManager(row.warehouse._id)" color="warn" style='background: #000;'><mat-icon class="sidenav-icon">add_box</mat-icon><b>add Manager</b></button>
            </ng-template>

          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 50, 25, 100]" showFirstLastButtons></mat-paginator>
    </div>

  </mat-card-content>
</mat-card>
