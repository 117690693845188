import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MagasinService {
  uri = localStorage.getItem('serverRoot');
  user = JSON.parse(sessionStorage.getItem('user'));

  constructor(private http: HttpClient) {
  }

  addMagasin(nameField, locationField, codeField) {
    const magasin = {
      name: nameField,
      location: locationField,
      code: codeField
    };
    return this.http.post(`${this.uri}/admins/${this.user._id}/addWareHouse`, magasin);
  }

  getWarehouses() {
    return this.http.get(`${this.uri}/admins/${this.user._id}/warehouses`);
  }

  getWarehouseById(id) {
    return this.http.get(`${this.uri}/warehouseManager/${id}`);
  }

  updateWarehouse(id, Title, FirstName, codeField) {


    const warehouse = {
      name: Title,
      location: FirstName,
      code: codeField
    };
    return this.http.put(`${this.uri}/admins/${this.user._id}/updateWarehouse/${id}`, warehouse);
  }

  deleteWarehouse(id) {
    const warehouse = {
      isDelete: true
    };
    return this.http.put(`${this.uri}/admins/${this.user._id}/deleteWarehouse/${id}`, warehouse);
  }

  deleteWarehousemanager(id) {
    const warehouse = {
      isDelete: true
    };
    return this.http.put(`${this.uri}/admins/${this.user._id}/deleteWarehousemanager/${id}`, warehouse);
  }

  addManager(id, manager) {

    const warehouse = {
      warehousemanager: manager,
      warehouse: id
    };
    return this.http.post(`${this.uri}/admins/${this.user._id}/assign`, warehouse);
  }

  changeWarehousestatus(id, status) {
    const warehouse = {
      status: status
    };
    return this.http.put(`${this.uri}/admins/${this.user._id}/updateWarehouseManager/${id}`, warehouse);
  }

  getCompanys() {
    return this.http.get(`${this.uri}/admins/${this.user._id}/managersWarehouse`);
  }
}
