<div *ngIf="data.id; else templateName">
    <h1 mat-dialog-title>Edit Product</h1>
</div>

<ng-template #templateName>
    <h1 mat-dialog-title>Add Product</h1>
</ng-template>
<div mat-dialog-content>
    <mat-form-field >
        <input matInput value="data.productno" [(ngModel)]="data.productno" placeholder="Product No">
    </mat-form-field>
    <mat-form-field>
        <input matInput value="data.productname" [(ngModel)]="data.productname" placeholder="Product Name">
    </mat-form-field>
    <mat-form-field>
        <input matInput value="data.brand" [(ngModel)]="data.brand" placeholder="Brand">
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">No Thanks</button>
    <button id="add" mat-button [mat-dialog-close]="data" cdkFocusInitial>Add</button>
</div>
