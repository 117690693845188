import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';

import {ViewdetailsComponent} from './viewdetails/viewdetails.component';
import {AuditService} from './audit.service';
import {Order} from './audit.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-grid',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss']
})
export class AuditComponent implements OnInit {
  displayedColumn = ['name', 'action', 'table', 'createdAt', 'actions'];
  dataSource: MatTableDataSource<Order>;
  orders: Order[] = [];
  user = JSON.parse(sessionStorage.getItem('user'));

  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private orderseserviceService: AuditService, public dialog: MatDialog) {
    this.fetchOrders();

    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource(this.orders);

    // Assign the data to the data source for the table to render
    // this.dataSource = new MatTableDataSource(this.orders);
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  fetchOrders() {

    Swal.fire({
      title: 'Chargement',
      html: 'It will closed',
      onOpen: () => {
        Swal.showLoading();
        this.orderseserviceService
          .getOrders()
          .subscribe((data: Order[]) => {
            this.orders = data;
            this.dataSource = new MatTableDataSource(this.orders);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            Swal.close();
          });
      },
      onClose: () => {
        // clearInterval(timerInterval)
      }
    }).then((result) => {
      if (
        // Read more about handling dismissals
        result.dismiss === Swal.DismissReason.timer
      ) {
        console.log('I was closed by the timer');
      }
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialogDetails(idDialog: string): void {

    const dialogDetail = this.dialog.open(ViewdetailsComponent, {
      width: '850px',
      data: {id: idDialog}
    });

    dialogDetail.afterClosed().subscribe(result => {
      // test et creation de l'order dans la bdd

      // result est l'objet qui comporte tous les champs
    });
  }

  fetchproduit() {
    this.orderseserviceService
      .getOrders()
      .subscribe((data: Order[]) => {
        this.orders = data;
      });
  }
}
