import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WarehouseserviceService {
  uri = localStorage.getItem('serverRoot');
  user = JSON.parse(sessionStorage.getItem('user'));

  constructor(private http: HttpClient) {
  }

  addWarehouse(Title, FirstName, LastName, Companies, PhoneNumber, password, password2) {
    const warehouse = {
      Title: Title,
      FirstName: FirstName,
      LastName: LastName,
      Companies: Companies,
      PhoneNumber: PhoneNumber,
      Password: password
    };
    return this.http.post(`${this.uri}/admins/${this.user._id}/addWarehouseManager`, warehouse);
  }

  getWarehouses() {
    return this.http.get(`${this.uri}/admins/${this.user._id}/warehousemanagers`);
  }

  getWarehouseById(id) {
    return this.http.get(`${this.uri}/warehouseManager/${id}`);
  }

  updateWarehouse(id, Title, FirstName, LastName, Companies, PhoneNumber, password) {

    if (password.length > 0) {

      const warehouse = {
        Title: Title,
        FirstName: FirstName,
        LastName: LastName,
        Companies: Companies,
        PhoneNumber: PhoneNumber,
        Password: password
      };

      return this.http.put(`${this.uri}/admins/${this.user._id}/updateWarehouseManager/${id}`, warehouse);
    } else {
      const warehouse = {
        Title: Title,
        FirstName: FirstName,
        LastName: LastName,
        Companies: Companies,
        PhoneNumber: PhoneNumber
      };
      return this.http.put(`${this.uri}/admins/${this.user._id}/updateWarehouseManager/${id}`, warehouse);
    }
  }

  deleteWarehouse(id) {
    const warehouse = {
      isDeleted: true
    };
    return this.http.put(`${this.uri}/admins/${this.user._id}/delWarehouseManager/${id}`, warehouse);
  }

  changeWarehousestatus(id, status) {
    const warehouse = {
      status: status
    };
    return this.http.put(`${this.uri}/admins/${this.user._id}/updateWarehouseManager/${id}`, warehouse);
  }

  getCompanys() {
    return this.http.get(`${this.uri}/admins/${this.user._id}/companys`);
  }
}
