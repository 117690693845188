import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import * as FileSaver from 'file-saver';
// let FileSaver = require('file-saver');
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-dialog-overview',
  templateUrl: './dialog-overview.component.html',
  styleUrls: ['./dialog-overview.component.scss']
})


export class DialogOverviewComponent implements OnInit {
  checked = false;
  url: string;
  name: string;
  datas: any = null;

  ngOnInit() {
  }

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.data.produits.forEach( (value) => {
      data[value] = '';
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSelectFile(event) {
    if (event.target.files && event.target.files[0]) {
      // var reader = new FileReader();
      const file = event.target.files[0];
      this.name = event.target.files[0].name;

      const formData: FormData = new FormData();
      formData.append('excelfile', file, file.name);
      this.data['fileLoad'] = formData;
    }
  }

  public simple() {
    // let store = JSON.parse(JSON.stringify(this.datas));
    const datas = [{
      firstname: 'firstname',
      lastname: 'lastname',
      employee_id: 'employee_id',
      pickup_location: 'pickup_location',
      department: 'department'
    }];
    // datas[0].
    this.data.produits.forEach((value) => {
      datas[0][value] = value;
    });
    // console.log(datas);

    this.exportAsExcelFile(datas, 'simple');
  }

  private exportAsExcelFile(json: any[], excelFileName: string): void {

    const ec = (rExcel, cExcel) => {
      return XLSX.utils.encode_cell({r: rExcel, c: cExcel});
    };

    const deleteRow = (ws, rowIndex) => {
      const range = XLSX.utils.decode_range(ws['!ref']);
      for (let R = rowIndex; R < range.e.r; ++R) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
          ws[ec(R, C)] = ws[ec(R + 1, C)];
        }
      }
      range.e.r--;
      ws['!ref'] = XLSX.utils.encode_range(range.s, range.e);
    };


    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    deleteRow(worksheet, 1);

    const workbook: XLSX.WorkBook = {Sheets: {'Sheet 1': worksheet}, SheetNames: ['Sheet 1']};

    const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});


    // XLSX.writeFile(workbook, filename);

    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});

    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
}
