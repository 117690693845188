import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LoginserviceService} from './loginservice.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  logoTwiga = 'assets/images/vector-yellow.gif';
  email: string;
  password: string;

  constructor(private loginserviceService: LoginserviceService, private router: Router) {
  }

  ngOnInit() {
  }

  onLogin() {
    if (this.email && this.password) {

      this.loginserviceService
        .logins(this.email, this.password)
        .subscribe((data) => {
          const responce = JSON.parse(JSON.stringify(data));
          if (responce.code === 200) {
            sessionStorage.setItem('isLoggedin', 'true');
            sessionStorage.setItem('user', JSON.stringify(responce.message));
            this.router.navigate(['/admin']);
          } else {
            alert(responce.message);
          }
        });
    }
  }
}
