<div *ngIf="data.id; else templateName">
    <h1 mat-dialog-title>Edit User</h1>
</div>

<ng-template #templateName>
    <h1 mat-dialog-title>Add New Company</h1>
</ng-template>
<div mat-dialog-content>
    <mat-form-field >
        <input matInput value="data.company" [(ngModel)]="data.company" placeholder="Company">
    </mat-form-field>
    <mat-form-field>
        <input matInput value="data.email" [(ngModel)]="data.email" placeholder="Email">
    </mat-form-field>
    <mat-form-field>
        <input matInput value="data.CustomerNumber" [(ngModel)]="data.CustomerNumber" placeholder="Customer Number">
    </mat-form-field>
    <mat-form-field>
        <input matInput value="data.Password" [(ngModel)]="data.Password" placeholder="Password">
    </mat-form-field>
    <mat-form-field>
        <input matInput value="data.ConfirmPassword" [(ngModel)]="data.ConfirmPassword" placeholder="Confirm Password">
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">No Thanks</button>
    <button id="add" mat-button [mat-dialog-close]="data" cdkFocusInitial>Add</button>
</div>
