import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
// import {Headers, RequestOptions} from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class ViewdetailserviceService {

  uri = localStorage.getItem('serverRoot');
  user = JSON.parse(sessionStorage.getItem('user'));

  constructor(private http: HttpClient) {
  }

  viewdetail(id) {
    return this.http.get(`${this.uri}/admins/${this.user._id}/entrees/${id}`);
  }
}
