import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatTableModule} from '@angular/material/table';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';

import {MatDialogModule} from '@angular/material/dialog';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {MagasindetailRoutingModule} from './magasindetail-routing.module';
import {MagasindetailComponent} from './magasindetail.component';
import {DialogOverviewComponent} from './dialog-overview/dialog-overview.component';
import {ViewdetailsComponent} from './viewdetails/viewdetails.component';
import {MagasindetailService} from './magasindetail.service';

@NgModule({
  imports: [CommonModule, MagasindetailRoutingModule,
    MatTableModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatSelectModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    MagasindetailComponent,
    DialogOverviewComponent,
    ViewdetailsComponent
  ],
  entryComponents: [
    DialogOverviewComponent,
    ViewdetailsComponent
  ],
  providers: [MagasindetailService]
})
export class MagasindetailModule {
}
