<div *ngIf="data.id; else templateName">
  <h1 mat-dialog-title>Edit Product</h1>
</div>

<ng-template #templateName>
  <h1 mat-dialog-title>Generate Rapport</h1>
</ng-template>
<div mat-dialog-content>
  <mat-card>
    <div fxFlex fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="50px">
      <div fxFlex>

        <mat-form-field>
          <input matInput [(ngModel)]="data.date" [matDatepicker]="picker" placeholder="Choose begin date">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div fxFlex>

        <mat-form-field>
          <input matInput [(ngModel)]="data.datefin" [matDatepicker]="pickers" placeholder="Choose end date">
          <mat-datepicker-toggle matSuffix [for]="pickers"></mat-datepicker-toggle>
          <mat-datepicker #pickers></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </mat-card>
  <br/>
  <!--<mat-card>-->
    <!--<div fxFlex fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="50px">-->
      <!--<div fxFlex>-->

        <!--<mat-form-field>-->
          <!--&lt;!&ndash;<mat-checkbox class="example-margin" [(ngModel)]="data.checked">by Warehouse</mat-checkbox>&ndash;&gt;-->
        <!--</mat-form-field>-->
      <!--</div>-->
      <!--<div fxFlex>-->

        <!--<mat-form-field>-->
          <!--&lt;!&ndash;<mat-checkbox class="example-margin" [(ngModel)]="data.summary">Summary</mat-checkbox>&ndash;&gt;-->
        <!--</mat-form-field>-->
      <!--</div>-->
    <!--</div>-->
  <!--</mat-card>-->
  <!--<mat-form-field>-->

  <div class="center">
    <mat-checkbox *ngIf="!(( !data.stock || !data.summary ) && !( !data.stock && !data.summary ))" class="example-margin" [(ngModel)]="data.checked">by Warehouse</mat-checkbox>
    <mat-checkbox *ngIf="!(( !data.stock || !data.checked ) && !( !data.stock && !data.checked ))" class="example-margin" [(ngModel)]="data.summary">Summary</mat-checkbox>
    <mat-checkbox *ngIf="!(( !data.summary || !data.checked ) && !( !data.summary && !data.checked ))" class="example-margin" [(ngModel)]="data.stock">Stock</mat-checkbox>
  </div>
  <br/>
  <br/>
  <!--</mat-form-field>-->

  <!--( a || b ) && !( a && b )-->
  <!--( tr || false ) && !( a && b )-->
  <mat-form-field *ngIf="data.checked && !data.summary">
    <mat-select style="z-index:1000;" [(value)]="data.warehouse" [(ngModel)]="data.warehouse" placeholder="Warehouse">
      <mat-option *ngFor="let company of data.warehouses" [value]="company.warehouse._id">
        {{company.warehouse.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">No Thanks</button>
  <button id="add" mat-button [mat-dialog-close]="data" cdkFocusInitial>Add</button>
</div>
