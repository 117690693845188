import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
  route = [];

  constructor(private router: Router) {
    this.router.events.subscribe((url: any) => this.route = url);
  }

  canActivate(route: ActivatedRouteSnapshot) {
    if (sessionStorage.getItem('isLoggedin')) {

      if ('admin/agent' === route.routeConfig.path || 'admin/audit' === route.routeConfig.path) {
        const user = JSON.parse(sessionStorage.getItem('user'));
        if (user.role === 'admin') {
          return true;
        }

        this.router.navigate(['/admin']);
        return false;
      }

      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }
}
