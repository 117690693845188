import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule} from '@angular/material/paginator';
import { MatSelectModule} from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule} from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AgentRoutingModule } from './agent-routing.module';
import { AgentComponent } from './agent.component';
import { DialogOverviewComponent } from './dialog-overview/dialog-overview.component';
import { AgentService } from './agentservice.service';
import { DialogAskComponent } from './dialog-ask/dialog-ask.component';

@NgModule({
    imports: [CommonModule, AgentRoutingModule,
      MatTableModule,
      MatFormFieldModule,
      MatPaginatorModule,
      MatSelectModule,
      MatInputModule,
      MatCardModule,
      MatButtonModule,
      MatIconModule,
      MatDialogModule,
      FormsModule,
      ReactiveFormsModule
    ],
    declarations: [
      AgentComponent,
      DialogOverviewComponent,
      DialogAskComponent
    ],
    entryComponents: [
        DialogOverviewComponent,
        DialogAskComponent
    ],
    providers: [AgentService]
})
export class AgentModule {}
