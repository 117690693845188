import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {DialogOverviewComponent} from './dialog-overview/dialog-overview.component';
import {AgentService} from './agentservice.service';
import {Agent} from './agent.model';
import {DialogAskComponent} from './dialog-ask/dialog-ask.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-black-page',
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.scss']
})
export class AgentComponent implements OnInit {
  displayedColumns = ['name', 'email', 'role', 'createdAt', 'actions'];
  dataSource: MatTableDataSource<Agent>;
  users: Agent[] = [];
  name: string;
  email: string;
  role: string;
  password: string;

  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private agentService: AgentService, public dialog: MatDialog) {

    this.fetchproduit();
    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource(this.users);
  }

  ngOnInit() {
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogOverviewComponent, {
      width: '450px',
      data: {
        name: this.name,
        email: this.email,
        role: this.role,
        password: this.password
      }
    });

    const Toast = this.Toast;
    dialogRef.afterClosed().subscribe(result => {
      // test et creation de la company dans la bdd
      // result est l'objet qui comporte tous les champs
      this.agentService.addAgent(result.name, result.email, result.role, result.password)
        .subscribe((data: Agent[]) => {
          // console.log(data);
          const responce = JSON.parse(JSON.stringify(data));
          if (responce.code === 200) {
            Toast.fire({
              icon: 'success',
              title: responce.message
            });

            this.fetchproduit();
          } else {
            Toast.fire({
              icon: 'error',
              title: responce.message,
            });
          }
        });
    });
  }

  openDialogWithId(idDialog: string): void {
    let company;
    this.users.forEach((value) => {
      if (idDialog === value._id) {
        company = value;
      }
    });

    const dialogRef = this.dialog.open(DialogOverviewComponent, {
      width: '450px',
      data: {
        id: idDialog,
        name: company.name,
        email: company.email,
        role: company.role,
        password: '',
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // test et moddification a la bdd
      // result est l'objet qui comporte tous les champs
      this.agentService.updateAgent(idDialog, result.name, result.email, result.role, result.password)
        .subscribe((data: Agent[]) => {
          this.fetchproduit();
        });
    });
  }

  fetchproduit() {

    Swal.fire({
      title: 'Chargement',
      html: 'It will closed',
      onOpen: () => {
        Swal.showLoading();
        this.agentService
          .getAgents()
          .subscribe((data: Agent[]) => {
            this.users = data;
            this.dataSource = new MatTableDataSource(this.users);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            Swal.close();
          });
      },
      onClose: () => {
        // clearInterval(timerInterval)
      }
    }).then((result) => {
      if (
        // Read more about handling dismissals
        result.dismiss === Swal.DismissReason.timer
      ) {
        console.log('I was closed by the timer');
      }
    });
  }

  delete(id: string): void {
    const dialogRef = this.dialog.open(DialogAskComponent, {
      width: '350px',
      data: {title: 'delete'}
    });

    const Toast = this.Toast;

    dialogRef.afterClosed().subscribe(result => {
      // test et moddification a la bdd
      // result est l'objet qui comporte tous les champs
      if (result) {
        this.agentService
          .deleteAgent(id)
          .subscribe((data: Agent[]) => {
            const responce = JSON.parse(JSON.stringify(data));
            if (responce.code === 200) {
              Toast.fire({
                icon: 'success',
                title: responce.message
              });

              this.fetchproduit();
            } else {
              Toast.fire({
                icon: 'error',
                title: responce.message
              });
            }
          });
      }
    });
  }

  changeAgent(id: string): void {
    const dialogRef = this.dialog.open(DialogAskComponent, {
      width: '350px',
      data: {title: 'change status'}
    });

    const Toast = this.Toast;
    dialogRef.afterClosed().subscribe(result => {
      // test et moddification a la bdd
      // result est l'objet qui comporte tous les champs
      if (result) {

        this.agentService
          .changeAgentstatus(id)
          .subscribe((data: Agent[]) => {
            const responce = JSON.parse(JSON.stringify(data));
            if (responce.code === 200) {

              Toast.fire({
                icon: 'success',
                title: responce.message
              });

              this.fetchproduit();
            } else {
              Toast.fire({
                icon: 'error',
                title: responce.message,
              });
            }
          });
      }
    });
  }
}
