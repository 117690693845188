import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent implements OnInit {
  pushRightClass = 'push-right';
  logoTwiga = 'assets/images/vector-yellow.gif';
  user = JSON.parse(sessionStorage.getItem('user'));

  constructor(public router: Router, private translate: TranslateService) {
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd && window.innerWidth <= 992 && this.isToggled()) {
        this.toggleSidebar();
      }
    });
  }

  ngOnInit() {
  }

  isToggled(): boolean {
    const dom: Element = document.querySelector('body');
    return dom.classList.contains(this.pushRightClass);
  }

  toggleSidebar() {
    const dom: any = document.querySelector('body');
    dom.classList.toggle(this.pushRightClass);
  }

  onLoggedout() {
    sessionStorage.removeItem('isLoggedin');
    sessionStorage.removeItem('user');
    this.router.navigate(['/login']);
  }

  onManageUser() {
    this.router.navigate(['/admin/agent']);
  }

  changeLang(language: string) {
    this.translate.use(language);
  }
}
