<mat-card>
    <mat-card-header>
        <mat-card-title>Checkbox</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-checkbox class="example-margin" [(ngModel)]="checked">Checked</mat-checkbox>
        <mat-checkbox class="example-margin" [(ngModel)]="indeterminate">Indeterminate</mat-checkbox>
        <label class="example-margin"><b>Align:</b></label>
        <mat-radio-group [(ngModel)]="labelPosition">
            <mat-radio-button class="example-margin" value="after">After</mat-radio-button>
            <mat-radio-button class="example-margin" value="before">Before</mat-radio-button>
        </mat-radio-group>
        <mat-checkbox class="example-margin" [(ngModel)]="disabled">Disabled</mat-checkbox>
    </mat-card-content>
</mat-card>

<mat-card class="result">
    <mat-card-header>
        <mat-card-title>Result</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-checkbox
            class="example-margin"
            [(ngModel)]="checked"
            [(indeterminate)]="indeterminate"
            [labelPosition]="labelPosition"
            [disabled]="disabled">
            I'm a checkbox
        </mat-checkbox>
    </mat-card-content>
</mat-card>
