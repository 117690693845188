<mat-toolbar id="backgroundwhite" class="fix-nav">
  <button type="button" mat-icon-button class="visible-md" (click)="toggleSidebar()">
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>
  <div class="nav-brand">
    <img id="imglogo" mat-card-image src="{{ logoTwiga }}" style="margin-top: -15px; height: 75px;">
  </div>
  <span class="nav-spacer"></span>
  <button class="hidden-sm" mat-icon-button [matMenuTriggerFor]="profile">
    <mat-icon>account_circle</mat-icon>
  </button>
  <mat-menu #profile="matMenu">
    <button mat-menu-item (click)="onManageUser()">
      <mat-icon>person</mat-icon>
      {{ 'Manage User Role' | translate }}
    </button>
    <button mat-menu-item (click)="onLoggedout()">
      <mat-icon>exit_to_app</mat-icon>
      <span>{{ 'Logout' | translate }}</span>
    </button>
  </mat-menu>
</mat-toolbar>
