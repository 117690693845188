<style media="screen">
    .ng-star-inserted, app-dashboard, br {
        display: block !important;
    }
</style>
<div class="mb-20" fxLayout="column" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
    <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
        <div fxFlex>
            <app-stat [bgClass]="'danger'" [icon]="'people_outline'" [count]="companys" [label]="'Company'"></app-stat>
        </div>
        <div fxFlex>
            <app-stat [bgClass]="'warn'" [icon]="'view_list'" [count]="orders" [label]="'Orders'"></app-stat>
        </div>
        <div fxFlex>
            <app-stat [bgClass]="'success'" [icon]="'shopping_cart'" [count]="produits" [label]="'Produits'"></app-stat>
        </div>
    </div>

    <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
        <div fxFlex *ngFor="let item of places">
            <mat-card>
                <div *ngIf="item.motadmin == ''">
                    <img mat-card-image [src]="item.imgSrc">
                </div>
                <div *ngIf="item.motadmin != ''">
                    <h1>{{ item.motadmin }}</h1>
                </div>
            </mat-card>
        </div>
    </div>
</div>
