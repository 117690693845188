import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatTableModule} from '@angular/material/table';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCardModule} from '@angular/material/card';

import {CompanyRoutingModule} from './company-routing.module';
import {CompanyComponent} from './company.component';
import {DialogOverviewComponent} from './dialog-overview/dialog-overview.component';
import {DialogComponent} from './dialog/dialog.component';
import {CompanyseserviceService} from './companyseservice.service';
import {DialogAskComponent} from './dialog-ask/dialog-ask.component';


@NgModule({
  imports: [
    CommonModule,
    CompanyRoutingModule,
    MatTableModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    CompanyComponent,
    DialogComponent,
    DialogOverviewComponent,
    DialogAskComponent
  ],
  entryComponents: [
    DialogOverviewComponent,
    DialogAskComponent
  ],
  providers: [CompanyseserviceService]
})
export class CompanyModule {
}
