<div *ngIf="data.id; else templateName">
    <h1 mat-dialog-title>Edit Warehouse Manager</h1>
</div>

<ng-template #templateName>
    <h1 mat-dialog-title>Add New Warehouse Manager</h1>
</ng-template>
<div mat-dialog-content>
    <mat-form-field >
      <mat-select style="z-index:1000;" [(value)]="data.Title" [(ngModel)]="data.Title" placeholder="Title">
        <mat-option *ngFor="let titles of ['Mr.','Mrs.']" [value]="titles">
          {{titles}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
        <input matInput value="data.FirstName" [(ngModel)]="data.FirstName" placeholder="First Name">
    </mat-form-field>
    <mat-form-field>
        <input matInput value="data.LastName" [(ngModel)]="data.LastName" placeholder="Last Name">
    </mat-form-field>
    <mat-form-field>
        <mat-select style="z-index:1000;" [(value)]="data.Companies" [(ngModel)]="data.Companies" placeholder="Companies">
          <mat-option *ngFor="let company of data.companys" [value]="company">
            {{company}}
          </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <input matInput value="data.PhoneNumber" [(ngModel)]="data.PhoneNumber" placeholder="Phone Number">
    </mat-form-field>
    <mat-form-field>
        <input matInput value="data.Password" [(ngModel)]="data.Password" placeholder="Password">
    </mat-form-field>
    <mat-form-field>
        <input matInput value="data.ConfirmPassword" [(ngModel)]="data.ConfirmPassword" placeholder="Confirm Password">
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">No Thanks</button>
    <button id="add" mat-button [mat-dialog-close]="data" cdkFocusInitial>Add</button>
</div>
