import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatTableModule} from '@angular/material/table';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';

import {MatDialogModule} from '@angular/material/dialog';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {MagasinRoutingModule} from './magasin-routing.module';
import {MagasinComponent} from './magasin.component';
import {DialogOverviewComponent} from './dialog-overview/dialog-overview.component';
import {MagasinService} from './magasin.service';
import {DialogAskComponent} from './dialog-ask/dialog-ask.component';
import {DialogManagerComponent} from './dialog-manager/dialog-manager.component';

@NgModule({
  imports: [CommonModule, MagasinRoutingModule,
    MatTableModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatSelectModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    MagasinComponent,
    DialogOverviewComponent,
    DialogAskComponent,
    DialogManagerComponent
  ],
  entryComponents: [
    DialogOverviewComponent,
    DialogAskComponent,
    DialogManagerComponent
  ],
  providers: [MagasinService]
})
export class MagasinModule {
}
