<div *ngIf="data.id; else templateName">
    <h1 mat-dialog-title>Order Item details</h1>
</div>

<ng-template #templateName>
    <h1 mat-dialog-title>Order Item details</h1>
</ng-template>
<div mat-dialog-content>
  <div>
      <table mat-table [dataSource]="dataSourceDetails" matSort>
          <!-- ID Column -->
          <ng-container matColumnDef="ProductName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Product Name </th>
              <td mat-cell *matCellDef="let row"> {{row.ProductName}} </td>
          </ng-container>
          <!-- ID Column -->
          <ng-container matColumnDef="Totalorderquantity">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Total order quantity </th>
              <td mat-cell *matCellDef="let row"> {{row.Totalorderquantity}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="Totalhandedoverquantity">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Total handed over quantity </th>
              <td mat-cell *matCellDef="let row"> {{row.Totalhandedoverquantity}} </td>
          </ng-container>

          <!-- Progress Column -->
          <ng-container matColumnDef="Remainingquantity">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Remaining quantity </th>
              <td mat-cell *matCellDef="let row"> {{row.Remainingquantity}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsDetails"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsDetails;"></tr>
      </table>

      <!-- <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 50, 25, 100]"></mat-paginator> -->
  </div>
</div>
<div mat-dialog-actions>
    <button mat-button color="warn" (click)="onNoClick()">Exit</button>
</div>
