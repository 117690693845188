<div *ngIf="data.id; else templateName">
  <h1 mat-dialog-title>Edit Warehouse Manager</h1>
</div>

<ng-template #templateName>
  <h1 mat-dialog-title>Add New Warehouse Manager</h1>
</ng-template>
<div mat-dialog-content>
  <mat-form-field>
    <input matInput value="data.name" [(ngModel)]="data.name" placeholder="Full name">
  </mat-form-field>
  <mat-form-field>
    <input matInput value="data.email" [(ngModel)]="data.email" placeholder="Email">
  </mat-form-field>
  <mat-form-field>
    <mat-select style="z-index:1000;" [(value)]="data.role" [(ngModel)]="data.role" placeholder="role">
      <mat-option *ngFor="let titles of ['admin','ReadOnlyUser','StockController']" [value]="titles">
        {{titles}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <input matInput value="data.password" [(ngModel)]="data.password" placeholder="password">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">No Thanks</button>
  <button id="add" mat-button [mat-dialog-close]="data" cdkFocusInitial>Add</button>
</div>
