<h1>Audit</h1>
<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h2>List</h2>
      <div class="addcomp">
      </div>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
    </mat-form-field>

    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>

            <!-- ID Column -->
            <ng-container matColumnDef="name">
                <th style='width: 7%;' mat-header-cell *matHeaderCellDef mat-sort-header> Nom complet </th>
                <td mat-cell *matCellDef="let row"> {{row.idUser.name}} </td>
            </ng-container>
            <!-- ID Column -->
            <ng-container matColumnDef="action">
                <th style='width: 7%;' mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
                <td mat-cell *matCellDef="let row"> {{row.actionExec}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="table">
                <th style='width: 12%;' mat-header-cell *matHeaderCellDef mat-sort-header> Table </th>
                <td mat-cell *matCellDef="let row"> {{row.tableExec}} </td>
            </ng-container>

            <!-- Progress Column -->
            <ng-container matColumnDef="createdAt">
                <th style='width: 14%;' mat-header-cell *matHeaderCellDef mat-sort-header> createdAt </th>
                <td mat-cell *matCellDef="let row"> {{row.createdAt}} </td>
            </ng-container>

            <!-- Color Column -->
            <ng-container matColumnDef="actions">
                <th style='width: 25%;' mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                <td mat-cell id='actions' *matCellDef="let row">
                    <button mat-raised-button class="btn-xs" color="warn" style='background: rgb(254, 192, 13);' (click)="openDialogDetails(row._id)"><mat-icon class="sidenav-icon">visibility</mat-icon><b>View details</b></button>
                 </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumn"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumn;"></tr>
        </table>
        <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 50, 25, 100]" showFirstLastButtons></mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
