import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {DialogOverviewComponent} from './dialog-overview/dialog-overview.component';
import {MagasindetailService} from './magasindetail.service';
import {Magasin, Produit} from './magasindetail.model';
import {ViewdetailsComponent} from './viewdetails/viewdetails.component';
import Swal from 'sweetalert2';
import {ActivatedRoute, Router} from '@angular/router';


@Component({
  selector: 'app-magasin',
  templateUrl: './magasindetail.component.html',
  styleUrls: ['./magasindetail.component.scss']
})
export class MagasindetailComponent implements OnInit {
  displayedColumns = ['productno', 'productname', 'brand', 'quantity', 'actions'];
  dataSource: MatTableDataSource<Produit>;
  users: Produit[] = [];
  Name: string;
  Location: string;
  dataWarehouse: string;
  dataWarehouses: {};
  warehousedetail: any;
  id: string;
  user = JSON.parse(sessionStorage.getItem('user'));
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private magasinService: MagasindetailService, public dialog: MatDialog, private activatedRoute: ActivatedRoute, private router: Router) {

    this.magasinService
      .getWarehouses()
      .subscribe((data) => {
        this.dataWarehouses = data;
      });
    this.fetchproduit();
    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource(this.users);
  }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  openDialogWithId(idDialog: string): void {
    let company;
    this.users.forEach((value) => {
      if (idDialog === value._id) {
        company = value;
      }
    });
    const Toast = this.Toast;

    const dialogRef = this.dialog.open(DialogOverviewComponent, {
      width: '450px',
      data: {
        id: idDialog,
        product: company.product._id,
        warehouse: company.warehouse,
        name: company.product.name,
        quantity: 0
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // test et moddification a la bdd
      // result est l'objet qui comporte tous les champs
      this.magasinService.addEntree(result.quantity, result.product, result.warehouse)
        .subscribe((data) => {
          this.fetchproduit();
        });

    });
  }

  async fetchproduit() {

    await Swal.fire({
      title: 'Chargement',
      html: 'It will closed',
      onOpen: () => {
        Swal.showLoading();
        this.magasinService
          .getWarehouseById(this.id)
          .subscribe((data: Magasin) => {
            this.warehousedetail = data.warehouse;
            this.users = data.products[0] as Produit[];
            this.dataSource = new MatTableDataSource(this.users);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            Swal.close();
          });
      },
      onClose: () => {
        // clearInterval(timerInterval)
      }
    }).then((result) => {
      if (
        // Read more about handling dismissals
        result.dismiss === Swal.DismissReason.timer
      ) {
        console.log('I was closed by the timer');
      }
    });
  }

  fetchproduits() {

    this.magasinService
      .getWarehouseById(this.id)
      .subscribe((data: Magasin) => {
        this.warehousedetail = data.warehouse;
        this.users = data.products[0] as Produit[];
        this.dataSource = new MatTableDataSource(this.users);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }

  openDialogDetails(idDialog: string): void {
    const dialogDetail = this.dialog.open(ViewdetailsComponent, {
      width: '850px',
      data: {id: idDialog}
    });

    dialogDetail.afterClosed().subscribe(result => {
      // test et creation de l'order dans la bdd

      // result est l'objet qui comporte tous les champs
    });
  }

  redirect(): void {
    if (this.dataWarehouse) {
      this.router.navigate(['admin/warehouse/' + this.dataWarehouse]);
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
  }
}
