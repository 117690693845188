<h1>Manage Order</h1>
<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h2>Order List</h2>
      <div class="addcomp">
         <button style='float: left;' mat-raised-button color="warn" (click)="getarchive()"><mat-icon class="sidenav-icon">archive</mat-icon> All Archive </button>
        <button *ngIf="user.role == 'admin'" style='background: #000;margin-left: 5px;' mat-raised-button (click)="openDialog()" color="warn"><mat-icon class="sidenav-icon">add_box</mat-icon> Add Order</button>
      </div>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
    </mat-form-field>

    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>

            <!-- ID Column -->
            <ng-container matColumnDef="end date">
                <th style='width: 7%;' mat-header-cell *matHeaderCellDef mat-sort-header> end date </th>
                <td mat-cell *matCellDef="let row"> {{row.oder_date_fin}} </td>
            </ng-container>
            <!-- ID Column -->
            <ng-container matColumnDef="ordernumber">
                <th style='width: 7%;' mat-header-cell *matHeaderCellDef mat-sort-header> Order Number </th>
                <td mat-cell *matCellDef="let row"> {{row.batch_number}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="companyname">
                <th style='width: 12%;' mat-header-cell *matHeaderCellDef mat-sort-header> Company Name </th>
                <td mat-cell *matCellDef="let row"> {{row.company}} </td>
            </ng-container>

            <!-- Progress Column -->
            <ng-container matColumnDef="fulfillmentoption">
                <th style='width: 14%;' mat-header-cell *matHeaderCellDef mat-sort-header> Fulfillment option </th>
                <td mat-cell *matCellDef="let row"> {{row.fullfilementoption}} </td>
            </ng-container>

            <!-- Color Column -->
            <ng-container matColumnDef="fulfillmentdate">
                <th style='width: 6%;' mat-header-cell *matHeaderCellDef mat-sort-header> Fulfillment Date </th>
                <td mat-cell *matCellDef="let row"> {{row.fullfilementdate}} </td>
            </ng-container>

            <!-- Color Column -->
            <ng-container matColumnDef="created">
                <th style='width: 8%;' mat-header-cell *matHeaderCellDef mat-sort-header> Created Date</th>
                <td mat-cell *matCellDef="let row"> {{row.batch_start_date}} </td>
            </ng-container>

            <!-- Color Column -->
            <ng-container matColumnDef="orderstatuts">
                <th style='width: 8%;' mat-header-cell *matHeaderCellDef mat-sort-header> Order Statuts</th>
                <td mat-cell *matCellDef="let row"> {{row.status}} </td>
            </ng-container>

            <!-- Color Column -->
            <ng-container matColumnDef="actions">
                <th style='width: 25%;' mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                <td mat-cell id='actions' *matCellDef="let row">
                    <button mat-raised-button class="btn-xs" color="warn" (click)='printOrders(row.batch_number)' style='background: rgb(254, 192, 13);'><mat-icon class="sidenav-icon">print</mat-icon><b>print</b></button>
                    <button mat-raised-button class="btn-xs" color="warn" style='background: rgb(254, 192, 13);' (click)="openDialogDetails(row.batch_number)"><mat-icon class="sidenav-icon">visibility</mat-icon><b>View details</b></button>
                  <!--<div *ngIf="archive === false">-->

                    <!--<div *ngIf="row.status == 'Approved'; else templateName">-->
                      <button *ngIf="user.role == 'admin' && row.status == 'Approved' && archive === false; else templateName" mat-raised-button class="btn-xs" color="warn" (click)='changeWarehouse(row._id,row.status)'><mat-icon class="sidenav-icon">thumb_up_alt</mat-icon><b>Reject</b></button>
                    <!--</div>-->

                    <ng-template #templateName>
                      <button *ngIf="user.role == 'admin'&& archive === false" mat-raised-button style='background: #000;' class="btn-xs" color="warn" (click)='changeWarehouse(row._id,row.status)'><mat-icon class="sidenav-icon">thumb_up_alt</mat-icon><b>Approved</b></button>
                    </ng-template>

                  <!--</div>-->
                  <!--<div *ngIf="archive === false">-->
                    <!--<div >-->
                      <button *ngIf="user.role == 'admin' && archive === false" mat-raised-button class="btn-xs" color="warn" (click)='delete(row._id)'><mat-icon class="sidenav-icon">delete_sweep</mat-icon><b>Archiver</b></button>
                    <!--</div>-->
                  <!--</div>-->
                    <button mat-raised-button class="btn-xs" color="warn" (click)='export(row.batch_number)' style='background: rgb(49, 176, 213);'><mat-icon class="sidenav-icon">description</mat-icon><b>Export Order</b></button>
                 </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumn"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumn;"></tr>
        </table>
        <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 50, 25, 100]" showFirstLastButtons></mat-paginator>
    </div>
  </mat-card-content>
</mat-card>
