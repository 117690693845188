import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {DialogOverviewComponent} from './dialog-overview/dialog-overview.component';
import {WarehouseserviceService} from './warehouseservice.service';
import {Warehouse} from './warehouse.model';
import {DialogAskComponent} from './dialog-ask/dialog-ask.component';
import {Company} from '../company/company.model';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-black-page',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.scss']
})
export class WarehouseComponent implements OnInit {
  displayedColumns = ['Title', 'FirstName', 'LastName', 'Companies', 'PhoneNumber', 'created', 'status', 'actions'];
  dataSource: MatTableDataSource<Warehouse>;
  users: Warehouse[] = [];
  companys: string[] = [];
  Title: string;
  FirstName: string;
  LastName: string;
  Companies: string;
  PhoneNumber: string;
  Password: string;
  ConfirmPassword: string;
  user = JSON.parse(sessionStorage.getItem('user'));
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private warehouseserviceService: WarehouseserviceService, public dialog: MatDialog) {
    this.warehouseserviceService
      .getCompanys()
      .subscribe((data: Company[]) => {
        const pass: string[] = [];
        data.forEach((value) => {
          pass.push(value.name);
        });

        this.companys = pass;
      });

    this.fetchproduit();
    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource(this.users);
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogOverviewComponent, {
      width: '450px',
      data: {
        Title: this.Title,
        FirstName: this.FirstName,
        LastName: this.LastName,
        Companies: this.Companies,
        PhoneNumber: this.PhoneNumber,
        Password: this.Password,
        ConfirmPassword: this.ConfirmPassword,
        companys: this.companys
      }
    });

    const Toast = this.Toast;
    dialogRef.afterClosed().subscribe(result => {
      if (result.Password === result.ConfirmPassword) {
        this.warehouseserviceService.addWarehouse(result.Title, result.FirstName, result.LastName, result.Companies, result.PhoneNumber, result.Password, result.ConfirmPassword)
          .subscribe((data: Warehouse[]) => {
            const responce = JSON.parse(JSON.stringify(data));
            if (responce.code === 200) {
              Toast.fire({
                icon: 'success',
                title: responce.message
              });

              this.fetchproduit();
            } else {
              Toast.fire({
                icon: 'error',
                title: responce.message,
              });
            }
          });
      } else {
        Toast.fire({
          icon: 'error',
          title: 'password are not the same',
        });
      }

    });
  }

  openDialogWithId(idDialog: string): void {
    let company;
    this.users.forEach((value) => {
      if (idDialog === value._id) {
        company = value;
      }
    });
    const Toast = this.Toast;

    const dialogRef = this.dialog.open(DialogOverviewComponent, {
      width: '450px',
      data: {
        id: idDialog,
        Title: company.Title,
        FirstName: company.FirstName,
        LastName: company.LastName,
        Companies: company.Companies,
        PhoneNumber: company.PhoneNumber,
        Password: '',
        ConfirmPassword: '',
        companys: this.companys
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // test et moddification a la bdd
      // result est l'objet qui comporte tous les champs
      if (result.ConfirmPassword === result.Password ) {
        this.warehouseserviceService.updateWarehouse(idDialog, result.Title, result.FirstName, result.LastName, result.Companies, result.PhoneNumber, result.Password)
          .subscribe((data: Warehouse[]) => {
            this.fetchproduit();
          });
      } else {
        Toast.fire({
          icon: 'error',
          title: 'password are not the same',
        });
      }

    });
  }

  fetchproduit() {

    Swal.fire({
      title: 'Chargement',
      html: 'It will closed',
      onOpen: () => {
        Swal.showLoading();
        this.warehouseserviceService
          .getWarehouses()
          .subscribe((data: Warehouse[]) => {
            this.users = data;
            this.dataSource = new MatTableDataSource(this.users);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            Swal.close();
          });
      },
      onClose: () => {
        // clearInterval(timerInterval)
      }
    }).then((result) => {
      if (
        // Read more about handling dismissals
        result.dismiss === Swal.DismissReason.timer
      ) {
        console.log('I was closed by the timer');
      }
    });
  }

  delete(id: string): void {
    const dialogRef = this.dialog.open(DialogAskComponent, {
      width: '350px',
      data: {title: 'delete'}
    });

    const Toast = this.Toast;

    dialogRef.afterClosed().subscribe(result => {
      // test et moddification a la bdd
      // result est l'objet qui comporte tous les champs
      if (result) {
        this.warehouseserviceService
          .deleteWarehouse(id)
          .subscribe((data: Warehouse[]) => {
            const responce = JSON.parse(JSON.stringify(data));
            if (responce.code === 200) {
              Toast.fire({
                icon: 'success',
                title: responce.message
              });

              this.fetchproduit();
            } else {
              Toast.fire({
                icon: 'error',
                title: responce.message
              });
            }
          });
      }
    });
  }

  changeWarehouse(id: string, status: string): void {
    const dialogRef = this.dialog.open(DialogAskComponent, {
      width: '350px',
      data: {title: 'change status'}
    });

    const Toast = this.Toast;
    dialogRef.afterClosed().subscribe(result => {
      // test et moddification a la bdd
      // result est l'objet qui comporte tous les champs
      if (result) {
        let newStatus;
        if (status === 'active') {
          newStatus = 'Deactive';
        } else {
          newStatus = 'active';
        }
        this.warehouseserviceService
          .changeWarehousestatus(id, newStatus)
          .subscribe((data: Warehouse[]) => {
            const responce = JSON.parse(JSON.stringify(data));
            if (responce.code === 200) {
              Toast.fire({
                icon: 'success',
                title: responce.message
              });

              this.fetchproduit();
            } else {
              Toast.fire({
                icon: 'error',
                title: responce.message,
              });
            }
          });
      }
    });
  }
}
