<div class="login-page">
    <div class="content">
        <!-- <h1 class="app-name">Sb Admin Material</h1> -->
        <div class="nav-brand">
              <img id="imglogo" mat-card-image src="{{ logoTwiga }}" style="margin-top: -15px; height: 75px;">
        </div>
        <form class="login-form" style="display: block;">
            <div class="text-center">
                <h2 class="app-name">Admin Login</h2>
            </div>
            <div fxFlex fxLayout="row" fxLayout.lt-md="column">
                <div fxFlexFill>
                    <mat-form-field  class="w-100">
                        <input matInput [(ngModel)]="email" [ngModelOptions]="{standalone: true}" placeholder="Email">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex fxLayout="row" fxLayout.lt-md="column">
                <div fxFlexFill>
                    <mat-form-field class="w-100">
                        <input matInput [(ngModel)]="password" [ngModelOptions]="{standalone: true}" type="password" placeholder="Password">
                    </mat-form-field>
                </div>
            </div>
            <div fxFlex fxLayout="row" fxLayout.lt-md="column">
                <div fxFlexFill>
                    <button mat-raised-button color="primary" class="w-100" (click)="onLogin()">Login</button>
                </div>
            </div>

        </form>
    </div>
</div>
