import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

// import { RequestOptions,Headers } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class OrderseserviceService {
  uri = localStorage.getItem('serverRoot');
  user = JSON.parse(sessionStorage.getItem('user'));

  constructor(private http: HttpClient) {
  }

  addOrder(company, fulfillmentoption, fulfillmentdate, datefin, file) {
    file.append('typeof', fulfillmentoption);
    file.append('orderdate', fulfillmentdate.toLocaleDateString());
    file.append('datefin', datefin.toLocaleDateString());

    file.append('company', company);
    const paramsCompany = new HttpParams();
    const head = new HttpHeaders();
    head.append('Content-Type', 'multipart/form-data');
    head.append('Accept', 'application/json');
    head.append('Access-Control-Allow-Origin', '*');
    head.append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
    const option = {
      headers: new HttpHeaders(),
      params: paramsCompany,
      reportProgress: true,
      withCredentials: true,
    };

    return this.http.post(`${this.uri}/admins/${this.user._id}/addOrder`, file);
  }

  addOrderForOn(company, fulfillmentoption, fulfillmentdate, datefin, client, produits) {
    const formData: FormData = new FormData();
    const pr = JSON.stringify(produits);
    formData.append('company', company);
    formData.append('fulfillmentoption', fulfillmentoption);
    formData.append('fulfillmentdate', fulfillmentdate.toLocaleDateString());
    formData.append('firstname', client.firstname);
    formData.append('lastname', client.lastname);
    formData.append('datefin', datefin.toLocaleDateString());
    formData.append('employeid', client.employeid);
    formData.append('location', client.location);
    formData.append('departement', client.departement);
    formData.append('produits', pr);
    return this.http.post(`${this.uri}/admins/${this.user._id}/addOnOrder`, formData);
  }

  getOrders() {
    return this.http.get(`${this.uri}/admins/${this.user._id}/orders`);
  }

  getOrdersArchive() {
    return this.http.get(`${this.uri}/admins/${this.user._id}/ordersarchive`);
  }

  getOrderById(id) {
    return this.http.get(`${this.uri}/order/${id}`);
  }

  deleteOrder(id, reasonField) {
    const order = {
      isDelete: true,
      reasondelete: reasonField
    };

    console.log(order);
    return this.http.put(`${this.uri}/admins/${this.user._id}/deleteOrder/${id}`, order);
  }

  changeOrderstatus(id, statusOrder, reasonField) {
    const order = {
      status: statusOrder,
      reasonvoid: reasonField
    };
    return this.http.put(`${this.uri}/admins/${this.user._id}/updateOrder/${id}`, order);
  }

  exportOrder(id) {
    return this.http.get(`${this.uri}/admins/${this.user._id}/export/${id}`);
  }

  exportOrderBydep(id, dep) {
    const order = {
      department: dep
    };
    return this.http.post(`${this.uri}/admins/${this.user._id}/exportOrderbyDepartement/${id}`, order);
  }

  printOrder(id) {
    return this.http.get(`${this.uri}/admins/${this.user._id}/printOrders/${id}`);
  }

  printOrderByDep(id, dep) {
    const order = {
      department: dep
    };
    return this.http.post(`${this.uri}/admins/${this.user._id}/byDepartement/${id}`, order);
  }


  getProduits() {
    return this.http.get(`${this.uri}/admins/${this.user._id}/products`);
  }

  getCompanys() {
    return this.http.get(`${this.uri}/admins/${this.user._id}/companys`);
  }
}
