<div *ngIf="data.id; else templateName">
  <h1 mat-dialog-title>Edit Warehouse</h1>
</div>

<ng-template #templateName>
  <h1 mat-dialog-title>Add New Warehouse</h1>
</ng-template>
<div mat-dialog-content>
  <mat-form-field>
    <mat-select style="z-index:1000;" [(value)]="data.manager" [(ngModel)]="data.manager" placeholder="Manager">
      <mat-option *ngFor="let company of data.managers" [value]="company.id">
        {{company.FirstName}} {{company.LastName}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">No Thanks</button>
  <button id="add" mat-button [mat-dialog-close]="data" cdkFocusInitial>Add</button>
</div>
