import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AgentService {
  uri = localStorage.getItem('serverRoot');
  user = JSON.parse(sessionStorage.getItem('user'));

  constructor(private http: HttpClient) {
  }

  addAgent(nameAgent, emailAgent, roleAgent, passwordAgent) {
    const agent = {
      name: nameAgent,
      email: emailAgent,
      role: roleAgent,
      password: passwordAgent
    };

    return this.http.post(`${this.uri}/admins/${this.user._id}/addAgent`, agent);
  }

  getAgents() {
    return this.http.get(`${this.uri}/admins/${this.user._id}/users`);
  }

  getAgentById(id) {
    return this.http.get(`${this.uri}/admins/${id}`);
  }

  updateAgent(id, name, email, role, password) {

    if (password.length > 0) {
      const agent = {
        name: name,
        email: email,
        role: role,
        password: password
      };
      return this.http.put(`${this.uri}/admins/${this.user._id}/updateAgent/${id}`, agent);
    } else {
      const agent = {
        name: name,
        email: email,
        role: role
      };
      return this.http.put(`${this.uri}/admins/${this.user._id}/updateAgent/${id}`, agent);
    }
  }

  deleteAgent(id) {
    return this.http.get(`${this.uri}/admins/delete/${id}`);
  }

  changeAgentstatus(id) {
    const agent = {
      isDelete: true
    };
    return this.http.put(`${this.uri}/admins/${this.user._id}/deleteAgent/${id}`, agent);
  }

  getCompanys() {
    return this.http.get(`${this.uri}/company`);
  }
}
