<div *ngIf="data.id; else templateName">
    <h1 mat-dialog-title>Lists Entrees</h1>
</div>

<ng-template #templateName>
    <h1 mat-dialog-title>Lists Entrees</h1>
</ng-template>
<mat-form-field>
  <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
</mat-form-field>
<div mat-dialog-content>
  <div>
      <table mat-table [dataSource]="dataSourceDetails" matSort>
          <!-- ID Column -->
          <ng-container matColumnDef="quantity">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> quantity </th>
              <td mat-cell *matCellDef="let row"> {{row.quantity}} </td>
          </ng-container>
          <!-- ID Column -->
          <ng-container matColumnDef="createdAt">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> createdAt </th>
              <td mat-cell *matCellDef="let row"> {{row.createdAt}} </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumnsDetails"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsDetails;"></tr>
      </table>

      <!-- <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 50, 25, 100]"></mat-paginator> -->
  </div>
  <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 50, 25, 100]" showFirstLastButtons></mat-paginator>
</div>
<div mat-dialog-actions>
    <button mat-button color="warn" (click)="onNoClick()">Exit</button>
</div>
