import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {ViewdetailserviceService} from './viewdetailservice.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-viewdetails',
  templateUrl: './viewdetails.component.html',
  styleUrls: ['./viewdetails.component.scss']
})
export class ViewdetailsComponent implements OnInit {
  dataSourceDetails: MatTableDataSource<Details>;
  details: Details[];
  displayedColumnsDetails = ['ProductName', 'Totalorderquantity', 'Totalhandedoverquantity', 'Remainingquantity'];

  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });

  ngOnInit() {
  }

  constructor(private viewdetailserviceService: ViewdetailserviceService,
              public dialogRef: MatDialogRef<ViewdetailsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    const Toast = this.Toast;

    Swal.fire({
      title: 'Chargement',
      html: 'It will closed',
      onOpen: () => {
        Swal.showLoading();

        this.viewdetailserviceService
          .viewdetail(data.id)
          .subscribe((data) => {
            // let responce = JSON.parse(JSON.stringify(data));
            // console.log(data);
            if (data['code'] == 200) {
              let det: Details[] = [];
              Object.keys(data['result']).forEach(function (k) {
                det.push({
                  ProductName: k,
                  Totalorderquantity: data['result'][k],
                  Totalhandedoverquantity: data['delivered'][k],
                  Remainingquantity: data['waint'][k]
                });
              });

              this.details = det;
              this.dataSourceDetails = new MatTableDataSource(det);
            } else {
              Toast.fire({
                icon: 'error',
                title: 'error'
              });
            }
            Swal.close();
          });
      },
      onClose: () => {
        // clearInterval(timerInterval)
      }
    }).then((result) => {
      if (
        // Read more about handling dismissals
        result.dismiss === Swal.DismissReason.timer
      ) {
        console.log('I was closed by the timer');
      }
    });

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

export interface Details {
  ProductName: string;
  Totalorderquantity: string;
  Totalhandedoverquantity: string;
  Remainingquantity: string;
}
