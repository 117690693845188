import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {ViewdetailserviceService} from './viewdetailservice.service';
import Swal from 'sweetalert2';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
// import {Details} from '../../order/viewdetails/viewdetails.component';

@Component({
  selector: 'app-viewdetails',
  templateUrl: './viewdetails.component.html',
  styleUrls: ['./viewdetails.component.scss']
})
export class ViewdetailsComponent implements OnInit {
  dataSourceDetails: MatTableDataSource<Details>;
  details: Details[];
  displayedColumnsDetails = ['quantity', 'createdAt'];

  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngOnInit() {
    this.dataSourceDetails.paginator = this.paginator;
    this.dataSourceDetails.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSourceDetails.filter = filterValue;
    if (this.dataSourceDetails.paginator) {
      this.dataSourceDetails.paginator.firstPage();
    }
  }

  constructor(private viewdetailserviceService: ViewdetailserviceService,
              public dialogRef: MatDialogRef<ViewdetailsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    const Toast = this.Toast;

    Swal.fire({
      title: 'Chargement',
      html: 'It will closed',
      onOpen: () => {
        Swal.showLoading();

        this.viewdetailserviceService
          .viewdetail(data.id)
          .subscribe((datas) => {

            if (datas['code'] === 200) {
              const det = datas['message'] as Details[];

              this.details = det;
              this.dataSourceDetails = new MatTableDataSource(det);
            } else {
              Toast.fire({
                icon: 'error',
                title: 'error'
              });
            }
            Swal.close();
          });
      },
      onClose: () => {
        // clearInterval(timerInterval)
      }
    }).then((result) => {
      if (
        // Read more about handling dismissals
        result.dismiss === Swal.DismissReason.timer
      ) {
        console.log('I was closed by the timer');
      }
    });

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

export interface Details {
  quantity: number;
  createdAt: string;
}
