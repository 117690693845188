import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../shared/guard/auth.guard';
import {LayoutComponent} from './layout.component';

import {DashboardModule} from './dashboard/dashboard.module';
import {WarehouseModule} from './warehouse/warehouse.module';
import {ProduitModule} from './produit/produit.module';
import {CompanyModule} from './company/company.module';
import {OrderModule} from './order/order.module';
import {AgentModule} from './agent/agent.module';
import {MagasinModule} from './magasin/magasin.module';
import {MagasindetailModule} from './magasindetail/magasindetail.module';
import {AuditModule} from './audit/audit.module';
import {RapportModule} from './rapport/rapport.module';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        redirectTo: 'admin'
      },
      {
        path: 'admin',
        canActivate: [AuthGuard],
        loadChildren: () => DashboardModule
      },
      {
        path: 'admin/agent',
        canActivate: [AuthGuard],
        loadChildren: () => AgentModule
      },
      {
        path: 'admin/manage-order',
        canActivate: [AuthGuard],
        loadChildren: () => OrderModule
      },
      {
        path: 'admin/company',
        canActivate: [AuthGuard],
        loadChildren: () => CompanyModule
      },
      {
        path: 'admin/manage-products',
        canActivate: [AuthGuard],
        loadChildren: () => ProduitModule
      },
      {
        path: 'admin/warehousemanager',
        canActivate: [AuthGuard],
        loadChildren: () => WarehouseModule
      },
      {
        path: 'admin/warehouse',
        canActivate: [AuthGuard],
        loadChildren: () => MagasinModule
      },
      {
        path: 'admin/warehouse/:id',
        canActivate: [AuthGuard],
        loadChildren: () => MagasindetailModule
      },
      {
        path: 'admin/audit',
        canActivate: [AuthGuard],
        loadChildren: () => AuditModule
      },
      {
        path: 'admin/rapport',
        canActivate: [AuthGuard],
        loadChildren: () => RapportModule
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule {
}
