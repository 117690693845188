<h1>Warehouse {{ warehousedetail.name }}</h1>
<!--<mat-card>-->
  <div *ngIf="dataWarehouses" fxFlex fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">
    <div fxFlex>
      <mat-form-field>
        <mat-select style="z-index:1000;" [(ngModel)]="dataWarehouse" placeholder="role">
          <mat-option *ngFor="let titles of dataWarehouses" [value]="titles.warehouse._id">
            {{titles.warehouse.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex>

      <!--<mat-form-field>-->
      <button (click)='redirect()' style='background: #000;' mat-raised-button color="warn">
        <mat-icon class="sidenav-icon">refresh</mat-icon>
        Refresh
      </button>
      <!--</mat-form-field>-->
    </div>
  </div>
<!--</mat-card>-->
<br/>
<mat-card>
  <mat-card-header>
    <mat-card-title>

    </mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
    </mat-form-field>

    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort>

        <!-- ID Column -->
        <ng-container matColumnDef="productno">
          <th style='width: 6%;' mat-header-cell *matHeaderCellDef mat-sort-header> product No.</th>
          <td mat-cell *matCellDef="let row"> {{row.product.productNo}}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="productname">
          <th style='width: 12%;' mat-header-cell *matHeaderCellDef mat-sort-header> Product Name</th>
          <td mat-cell *matCellDef="let row"> {{row.product.name}}</td>
        </ng-container>

        <!-- Progress Column -->
        <ng-container matColumnDef="brand">
          <th style='width: 12%;' mat-header-cell *matHeaderCellDef mat-sort-header> Brand</th>
          <td mat-cell *matCellDef="let row"> {{row.product.brand}}</td>
        </ng-container>

        <!-- Progress Column -->
        <ng-container matColumnDef="quantity">
          <th style='width: 12%;' mat-header-cell *matHeaderCellDef mat-sort-header> Quantity</th>
          <td mat-cell *matCellDef="let row"> {{row.quantity}}</td>
        </ng-container>

        <!-- Color Column -->
        <ng-container matColumnDef="actions">
          <th style='width: 20%;' mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
          <td mat-cell id='actions' *matCellDef="let row">
            <button *ngIf="user.role == 'admin' || user.role == 'StockController'" mat-raised-button style='background: #000;' class="btn-xs"
                    (click)="openDialogWithId(row._id)" color="warn">
              <mat-icon class="sidenav-icon">add_box</mat-icon>
              Add Entree
            </button>
            <button mat-raised-button class="btn-xs" (click)="openDialogDetails(row._id)" color="warn"
                    style='background: rgb(254, 192, 13);'>
              <mat-icon class="sidenav-icon">visibility</mat-icon>
              <b>View Entree</b></button>
          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 50, 25, 100]" showFirstLastButtons></mat-paginator>
    </div>

  </mat-card-content>
</mat-card>
