<div *ngIf="data.id; else templateName">
  <h1 mat-dialog-title>Edit Warehouse</h1>
</div>

<ng-template #templateName>
  <h1 mat-dialog-title>Add New Warehouse</h1>
</ng-template>
<div mat-dialog-content>
  <mat-form-field>
    <input matInput value="data.name" [(ngModel)]="data.name" placeholder="Name">
  </mat-form-field>
  <mat-form-field>
    <input matInput value="data.locations" [(ngModel)]="data.locations" placeholder="Location">
  </mat-form-field>
  <mat-form-field>
    <input matInput value="data.code" [(ngModel)]="data.code" placeholder="Code">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">No Thanks</button>
  <button id="add" mat-button [mat-dialog-close]="data" cdkFocusInitial>Add</button>
</div>
