import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';

import {DialogOverviewComponent} from './dialog-overview/dialog-overview.component';
import {PrduitserviceService} from './prduitservice.service';
import {Produit} from './produit.model';
import {DialogAskComponent} from './dialog-ask/dialog-ask.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-blank-page',
  templateUrl: './produit.component.html',
  styleUrls: ['./produit.component.scss']
})
export class ProduitComponent implements OnInit {
  displayedColumns = ['productno', 'productname', 'brand', 'status', 'created', 'actions'];
  dataSource: MatTableDataSource<Produit>;
  produits: Produit[];
  user = JSON.parse(sessionStorage.getItem('user'));
  productno: string;
  productname: string;
  brand: string;
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private prduitserviceService: PrduitserviceService, public dialog: MatDialog) {
    // Create 100 users
    // for (let i = 1; i < 5; i++) {
    // this.produits.push(createNewUser(i));
    // }
    this.fetchproduit();
    // // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource(this.produits);
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // setTimeout(() => this.dataSource.paginator = this.paginator);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogOverviewComponent, {
      width: '450px',
      data: {productno: this.productno, productname: this.productname, brand: this.brand}
    });

    const Toast = this.Toast;

    dialogRef.afterClosed().subscribe(result => {
      // test et creation du produit dans la bdd
      // result est l'objet qui comporte tous les champs
      if (result) {
        this.prduitserviceService
          .addProduit(result.productno, result.productname, result.brand)
          .subscribe((data: Produit[]) => {
            const responce = JSON.parse(JSON.stringify(data));
            if (responce.code === 200) {
              Toast.fire({
                icon: 'success',
                title: responce.message
              });

              this.fetchproduit();
            } else {
              Toast.fire({
                icon: 'error',
                title: responce.message
              });
            }
          });
      }

    });
  }

  openDialogWithId(idDialog: string): void {
    let company;
    this.produits.forEach(function (value) {
      if (idDialog === value._id) {
        company = value;
      }
    });
    const dialogRef = this.dialog.open(DialogOverviewComponent, {
      width: '450px',
      data: {id: idDialog, productno: company.productNo, productname: company.name, brand: company.brand}
    });

    const Toast = this.Toast;
    dialogRef.afterClosed().subscribe(result => {
      // test et moddification a la bdd
      // result est l'objet qui comporte tous les champs
      this.prduitserviceService
        .updateProduit(idDialog, result.productno, result.productname, result.brand)
        .subscribe((data: Produit[]) => {
          const responce = JSON.parse(JSON.stringify(data));
          if (responce.code === 200) {
            Toast.fire({
              icon: 'success',
              title: responce.message
            });

            this.fetchproduit();
          } else {
            Toast.fire({
              icon: 'error',
              title: responce.message
            });
          }
        });
    });
  }

  fetchproduit() {

    Swal.fire({
      title: 'Chargement',
      html: 'It will closed',
      onOpen: () => {
        Swal.showLoading();
        this.prduitserviceService
          .getProduits()
          .subscribe((data: Produit[]) => {
            this.produits = data;
            this.dataSource = new MatTableDataSource(data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            Swal.close();
          });
      },
      onClose: () => {
        // clearInterval(timerInterval)
      }
    }).then((result) => {
      if (
        // Read more about handling dismissals
        result.dismiss === Swal.DismissReason.timer
      ) {
        console.log('I was closed by the timer');
      }
    });
  }

  delete(id: string): void {
    const dialogRef = this.dialog.open(DialogAskComponent, {
      width: '350px',
      data: {title: 'delete'}
    });

    const Toast = this.Toast;

    dialogRef.afterClosed().subscribe(result => {
      // test et moddification a la bdd
      // result est l'objet qui comporte tous les champs
      if (result) {
        this.prduitserviceService
          .deleteProduit(id)
          .subscribe((data: Produit[]) => {
            const responce = JSON.parse(JSON.stringify(data));
            if (responce.code === 200) {
              Toast.fire({
                icon: 'success',
                title: responce.message
              });

              this.fetchproduit();
            } else {
              Toast.fire({
                icon: 'error',
                title: responce.message
              });
            }
          });
      }
    });
  }

  changeCompanystatus(id: string, status: string): void {
    const dialogRef = this.dialog.open(DialogAskComponent, {
      width: '350px',
      data: {title: 'change status'}
    });

    const Toast = this.Toast;

    dialogRef.afterClosed().subscribe(result => {
      // test et moddification a la bdd
      // result est l'objet qui comporte tous les champs
      if (result) {
        this.prduitserviceService
          .changeProduitstatus(id, !status)
          .subscribe((data: Produit[]) => {
            const responce = JSON.parse(JSON.stringify(data));
            if (responce.code === 200) {
              Toast.fire({
                icon: 'success',
                title: responce.message
              });

              this.fetchproduit();
            } else {
              Toast.fire({
                icon: 'error',
                title: responce.message
              });
            }
          });
      }
    });
  }

}
