import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatTableModule} from '@angular/material/table';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {ProduitRoutingModule} from './produit-routing.module';
import {ProduitComponent} from './produit.component';
import {DialogOverviewComponent} from './dialog-overview/dialog-overview.component';
import {PrduitserviceService} from './prduitservice.service';
import {DialogAskComponent} from './dialog-ask/dialog-ask.component';

@NgModule({
  imports: [CommonModule, ProduitRoutingModule,
    MatTableModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    ProduitComponent,
    DialogOverviewComponent,
    DialogAskComponent
  ],
  entryComponents: [
    DialogOverviewComponent,
    DialogAskComponent
  ],
  providers: [PrduitserviceService]
})
export class ProduitModule {
}
